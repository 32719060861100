import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { ROUTE_LEFT_MENU } from '../../../App';
import { LeftMenu } from '../../../components';
import { userPropertiesSelector } from '../../../store/selectors';

import MyAccount from '../components/MyAccount';
import BillingDetails from '../components/BillingDetails';
import Reports from '../components/Reports';
import EditAccount from '../components/EditAccount';

import styles from './Settings.jss';

class Setings extends PureComponent {
	state = {
		leftMenu: 'settings',
		loading: false,
		editBillingDetails: false,
	};

	static propTypes = {
		userProperties: PropTypes.object,
	};

	handleOnClickLeftMenu = (el) => {
		this.props.history.push(ROUTE_LEFT_MENU[el.name]);
	};

	handleEditAccount = () => {
		const { editBillingDetails } = this.state;
		this.setState({ editBillingDetails: !editBillingDetails });
	};

	render() {
		const billingInfoIsForbidden = this.props.userProperties?.parentId;
		const { classes } = this.props;
		const { leftMenu, editBillingDetails } = this.state;
		return (
			<div className={classes.container}>
				<LeftMenu active={leftMenu} onClick={this.handleOnClickLeftMenu} />
				{editBillingDetails ? (
					<EditAccount onClose={this.handleEditAccount} />
				) : (
					[
						<div className={classes.content} key='account'>
							<MyAccount />
							{!billingInfoIsForbidden && (
								<BillingDetails onClickEditAccount={this.handleEditAccount} />
							)}
						</div>,
						<Reports key='report' />,
					]
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userProperties: userPropertiesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Setings));
