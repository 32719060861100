import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import get from 'get-value';

import pubsub, { EVENT } from '../../../lib/pubsub';
import { LeftMenu, IconButton, Confirm } from '../../../components';
import { ROUTE_LEFT_MENU } from '../../../App';
import BoundaryActions from '../../../store/BoundaryRedux';
import GeocodingActions from '../../../store/GeocodingRedux';
import UserActions, {
	adaptSubscriptionFromApi,
	adaptCardFromApi,
} from '../../../store/UserRedux';
import { NAME } from '../../../store/HomeRedux';
import {
	mapSelector,
	userNeedsAdditionInformation,
	userPropertiesSelector,
	userSubscribeDetails,
	mapMovedSelector,
	mapDraggedSelector,
	geocodingAdaptSourceData,
	userIsAdmin,
	mapZoomedSelector,
	mapUserBehaviourSelector,
	geocodingGetUsualData,
	geocodingGetCrimeData,
} from '../../../store/selectors';
import { api } from '../../../sagas';
import { getFeaturesBounds } from '../../../store/selectors/boundary';

import PersonPanel from '../components/PersonPanel';
import ReportPanel from '../components/ReportPanel';
import EditAccount from '../../Settings/components/EditAccount';
import { Report } from '../../Report';
import NoSubscription from '../../Layout/containers/NoSubscription';
import AddCard from '../../AddCard/components/AddCard';

import styles from './Home.jss';

class Home extends PureComponent {
	constructor(props) {
		super(props);

		this.initialState = {
			leftMenu: 'logo',
			showCompareWith: true,
			firstPanelName: NAME.ME,
			boundaries: null,
			loading: false,
			travelingToClicked: false,
			selectedPersonId: null,
			renderReportPanel: false,
			hideReportPanel: false,
			showReport: false,
			reportData: null,
			reportPanelLoading: false,
			showReportBillableAlert: false,
			subscription: null,
			showNoSubscription: false,
			bankCard: null,
			showAddBankCard: false,
			showEditBillingDetails: false,
			hidePersonPanel: false,
			classToHide: false,
		};

		this.state = this.initialState;

		this.personPanel = null;
	}

	static propTypes = {
		map: PropTypes.object,
		mapUserBehaviour: PropTypes.bool,
		userNeedsAdditionInformation: PropTypes.bool,
		userMapboxHomeDataData: PropTypes.object,
		mapMoved: PropTypes.number,
		mapZoomed: PropTypes.number,
		subscribeDetails: PropTypes.object,

		setMapUserBehaviour: PropTypes.func.isRequired,
		boundaryListSuccess: PropTypes.func.isRequired,
		getDetails: PropTypes.func.isRequired,
	};

	componentDidUpdate(prevProps) {
		const {
			map,
			mapDragged,
			mapZoomed,
			boundaryListSuccess,
			mapUserBehaviour,
			userProperties,
		} = this.props;

		if (
			(mapDragged && mapDragged !== prevProps.mapDragged) ||
			(mapZoomed && mapZoomed !== prevProps.mapZoomed)
		) {
			const bounds = map.getBounds();
			const enabledRequestOnDragMap = userProperties.enabledRequestOnDragMap;
			const { isAdmin } = this.props;

			if (mapUserBehaviour && bounds && isAdmin && enabledRequestOnDragMap) {
				this.getBoundaries(bounds).then((data) => {
					if (data) {
						this.setSource(data, false);
						boundaryListSuccess(data.features);
					}
				});
			}
		}
	}

	loadSubscription() {
		const { userProperties } = this.props;
		if (userProperties.billable) {
			return new Promise((resolve, reject) => {
				api.getSubscription().then((response) => {
					if (response.ok) {
						resolve({
							subscription: adaptSubscriptionFromApi(
								get(response, 'data', { default: {} })
							),
						});
					}
					reject();
				});
			});
		} else {
			return new Promise((resolve, reject) => {
				resolve({ subscription: null });
			});
		}
	}

	loadCreditCard() {
		return new Promise((resolve, reject) => {
			api.getBankCard().then((response) => {
				if (response.ok) {
					resolve({
						bankCard: adaptCardFromApi(get(response, 'data', { default: {} })),
					});
				}
				reject();
			});
		});
	}

	isPointsNotFar(point1, point2) {
		if (point1 && point2) {
			const [x1, y1] = point1;
			const [x2, y2] = point2;
			if (
				Math.abs((x1 / x2 - 1) * 100) < 1 &&
				Math.abs((y1 / y2 - 1) * 100) < 1
			)
				return true;
		}
		return false;
	}

	handleOnClickLeftMenu = (el) => {
		const { leftMenu } = this.state;
		if (el.name === leftMenu) {
			this.setState(this.initialState);
			if (this.personPanel) this.personPanel.reset();
		}
		this.props.history.push(ROUTE_LEFT_MENU[el.name]);
	};

	handleOnChangeLocation = ({ place, data }) => {
		const { map, setMapUserBehaviour } = this.props;
		if (map) {
			setMapUserBehaviour(false);
			map.flyTo({
				center: [place.longitude, place.latitude],
			});
			let action = 0;
			map.once('moveend', () => {
				action++;
			});
			map.once('zoomend', () => {
				action++;
			});
			const timer = setInterval(() => {
				if (action === 2) {
					setMapUserBehaviour(true);
					clearInterval(timer);
				}
			}, 300);
			const src = map.getSource('world');

			src.setData(geocodingAdaptSourceData(data));
		}
	};

	handleOnChangePerson = (personId) => {
		this.setState({ selectedPersonId: personId });
	};

	handleShowPersonPanel = () => {
		window.location.reload();
	};

	handleClickTravelingTo = () => {
		this.setState({ travelingToClicked: true });
	};

	handleOnPerconPanelCancel = () => {
		this.setState({ travelingToClicked: false });
	};

	handleClickGenerateReport = ({
		person,
		modifiers,
		residence,
		destination,
	}) => {
		this.setState({
			renderReportPanel: true,
			reportData: { person, modifiers, residence, destination },
		});
	};

	handleClickChangeData = () => {
		this.setState({ renderReportPanel: false });
		pubsub.publish(EVENT.LAYOUT_ADD_CLASS, ['layout-hide-children']);
	};

	handleClickReturnChangeData = () => {
		this.setState({ renderReportPanel: false });
	};

	handleClickGenerateReport2 = (selectedPlan) => {
		const { userProperties, subscribeDetails } = this.props;

		console.log('### Home::userProperties:', userProperties);

		if (userProperties.parentId) {
			console.log('### Billing type: parent account');
			this.setState({ showReport: true });
			this.handleClickChangeData();
		} else if (userProperties.billable) {
			console.log('### Billing type: ordinary account');
			// } else if (!userProperties.billable) {
			this.setState({ reportPanelLoading: true });
			this.loadSubscription().then((data) => {
				// console.log('### data: ', data);

				const subscription = get(data, 'subscription');
				const subscriptionId = get(data, 'subscription.id');
				const planId2 = get(selectedPlan, 'planId');
				const { planId } = subscribeDetails;
				if (!subscriptionId && !planId && !planId2) {
					this.setState({ showNoSubscription: true });
				} else {
					// if (1 === 1) {
					this.setState({
						hidePersonPanel: true,
						subscription,
					});
					const nextReportBillable = get(subscription, 'nextReportBillable', {
						default: false,
					});
					this.loadCreditCard().then((cardData) => {
						const bankCard = get(cardData, 'bankCard');
						const cardLast4 = get(cardData, 'bankCard.last4');
						this.setState({ bankCard });
						// if (!cardLast4) {
						if (cardLast4) {
							if (nextReportBillable) {
								this.setState({
									showReportBillableAlert: true,
								});
							} else {
								this.setState({
									showAddBankCard: false,
									hidePersonPanel: false,
									showReport: true,
									classToHide: true,
								});
								this.handleClickChangeData();
							}
						} else {
							this.setState({
								hidePersonPanel: true,
								renderReportPanel: false,
								showAddBankCard: true,
							});
						}
					});
				}
				this.setState({ reportPanelLoading: false });
			});
		} else {
			console.log('### Billing type: admin account');
			this.setState({ showReport: true });
			this.handleClickChangeData();
		}
	};

	handleClickDashboard = () => {
		this.setState({ showReport: false, classToHide: false });
		pubsub.publish(EVENT.LAYOUT_DEL_CLASS, ['layout-hide-children']);
	};

	handleCancelBillableInfo = () => {
		this.setState({
			showAddBankCard: false,
			showReportBillableAlert: false,
		});
	};

	handleConfirmBillableInfo = () => {
		this.setState({
			showAddBankCard: false,
			showReportBillableAlert: false,
			showReport: true,
			hidePersonPanel: false,
		});
		this.handleClickChangeData();
	};

	handleSelectPlan = (data) => {
		this.setState({
			hidePersonPanel: true,
			renderReportPanel: false,
			showNoSubscription: false,
		});
		this.handleClickGenerateReport2(data);
	};

	handleCardConfirm = () => {
		const { subscribeDetails } = this.props;
		const { planId, promoCode } = subscribeDetails;
		if (planId) {
			this.setState({ reportPanelLoading: true });
			api.subscribe({ planId, promoCode }).then((response) => {
				if (response.ok) {
					this.handleClickGenerateReport2();
				}
				this.setState({ reportPanelLoading: false });
			});
		}
	};

	handleEditBillingDetails = () => {
		this.setState({ showEditBillingDetails: true });
	};

	handleEditAccountClose = () => {
		this.setState({ showEditBillingDetails: false });
	};

	setSource(data, fitBounds = true) {
		this.setSourceUsual(geocodingGetUsualData(data), fitBounds);
		this.setSourceCrime(geocodingGetCrimeData(data), fitBounds);
	}

	setSourceUsual(data, fitBounds = true) {
		const { map } = this.props;
		if (map && data) {
			map.getSource('world').setData(geocodingAdaptSourceData(data));
			if (fitBounds) {
				const bounds = getFeaturesBounds(data.features);
				if (bounds) {
					map.fitBounds([bounds._ne, bounds._sw], {
						padding: {
							left: 300,
							top: 100,
							right: 50,
							bottom: 100,
						},
					});
				} else {
					// TODO Error
				}
			}
		}
	}

	setSourceCrime(data, fitBounds = true) {
		const { map } = this.props;
		if (map && data) {
			map.getSource('world-crime').setData(geocodingAdaptSourceData(data));
			if (fitBounds) {
				const bounds = getFeaturesBounds(data.features);
				if (bounds) {
					map.fitBounds([bounds._ne, bounds._sw], {
						padding: {
							left: 300,
							top: 100,
							right: 50,
							bottom: 100,
						},
					});
				} else {
					// TODO Error
				}
			}
		}
	}

	getBoundaries(bounds) {
		const { isAdmin } = this.props;
		this.setState({ loading: true });
		return new Promise((resolve, reject) => {
			api
				.getBoundaries(bounds, isAdmin)
				.then((response) => {
					resolve(response.data);
					this.setState({
						boundaries: response.data,
						loading: false,
					});
				})
				.catch((e) => {
					// TODO show error
					reject(e);
					this.setState({
						boundaries: null,
						loading: false,
					});
				});
		});
	}

	getContent() {
		const { classes, userNeedsAdditionInformation, userProperties } =
			this.props;
		const {
			travelingToClicked,
			selectedPersonId,
			renderReportPanel,
			reportData,
			reportPanelLoading,
			hidePersonPanel,
		} = this.state;
		if (userProperties.id) {
			if (userNeedsAdditionInformation) {
				return <EditAccount />;
			} else {
				const personPanelClass =
					renderReportPanel || hidePersonPanel ? classes.panelHidden : '';
				return [
					<PersonPanel
						key='person'
						panelClassName={personPanelClass}
						name={NAME.ME}
						user={userProperties}
						onChangePerson={this.handleOnChangePerson}
						onChangeLocation={this.handleOnChangeLocation}
						onClickGenerateReport={this.handleClickGenerateReport}
						closable={false}
						watchMapEvents={true}
						showCompareWith={false}
						showFullPanel={travelingToClicked}
						onCancel={this.handleOnPerconPanelCancel}
						forwardRef={(instance) => {
							this.personPanel = instance;
						}}
					/>,
					selectedPersonId && !travelingToClicked ? (
						<IconButton
							key='compare-with'
							aria-label='Traveling to'
							onClick={this.handleClickTravelingTo}
						>
							<div className={classes.travelingTo}>Traveling to...</div>
						</IconButton>
					) : null,
					renderReportPanel ? (
						<ReportPanel
							key='reportPanel'
							data={reportData}
							onClickChangeData={this.handleClickReturnChangeData}
							onClickGenerateReport={this.handleClickGenerateReport2}
							loading={reportPanelLoading}
						/>
					) : null,
				];
			}
		}
		return null;
	}

	render() {
		const { classes } = this.props;
		const {
			leftMenu,
			showReport,
			showReportBillableAlert,
			subscription,
			showNoSubscription,
			showAddBankCard,
			showEditBillingDetails,
			classToHide,
		} = this.state;

		const collectionMethod = get(subscription, 'collectionMethod');
		const billableAlertMessage =
			collectionMethod === 'send_invoice'
				? 'You have exceeded the number of free reports in the current period. Therefore, this report will be included to the list of additional items in the upcoming invoice. Do you want to proceed?'
				: 'You have exceeded the number of free/included reports for the current billing period. This report will be billed according to your subscription plan. Do you want to proceed?';
		return (
			<div className={classes.container}>
				<LeftMenu active={leftMenu} onClick={this.handleOnClickLeftMenu} />
				{this.getContent()}
				{showReport ? (
					<div className={`${classes.report} home-report`}>
						<Report onClickDashboard={this.handleClickDashboard} />
					</div>
				) : null}
				{showNoSubscription ? (
					<div className={classes.report}>
						<NoSubscription onSelectPlan={this.handleSelectPlan} />
					</div>
				) : null}
				{showAddBankCard ? (
					<div className={classes.addCard}>
						<AddCard
							onCardConfirm={this.handleCardConfirm}
							onClickEditBillingDetails={this.handleEditBillingDetails}
						/>
					</div>
				) : null}
				{showEditBillingDetails ? (
					<div className={classes.editAccount}>
						<EditAccount onClose={this.handleEditAccountClose} />
					</div>
				) : null}
				{showReportBillableAlert && (
					<div className={classes.overlay}>
						<Confirm
							message={billableAlertMessage}
							cancel='No'
							onCancel={this.handleCancelBillableInfo}
							confirm='Yes'
							onConfirm={this.handleConfirmBillableInfo}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	map: mapSelector(state),
	mapUserBehaviour: mapUserBehaviourSelector(state),
	userNeedsAdditionInformation: userNeedsAdditionInformation(state),
	userProperties: userPropertiesSelector(state),
	mapMoved: mapMovedSelector(state),
	mapDragged: mapDraggedSelector(state),
	mapZoomed: mapZoomedSelector(state),
	subscribeDetails: userSubscribeDetails(state),
	isAdmin: userIsAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
	boundaryListSuccess: (data) =>
		dispatch(BoundaryActions.boundaryListSuccess(data)),
	setMapUserBehaviour: (value) =>
		dispatch(GeocodingActions.setMapUserBehaviour(value)),
	getDetails: () => dispatch(UserActions.userDetailsRequest()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Home));
