import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { saveAs } from 'file-saver';
import DataLayer from '../DataLayer';
import Download from '../Download';
import TabButton from '../../../../components/TabButton';
import cn from '../../../../lib/classNames';
import html2canvas from '../../../../lib/html2canvas';
import StatisticsDistributionViCRI from '../StatisticsDistributionViCRI/StatisticsDistributionViCRI';
import TooltipVicri from '../TooltipVicri';

import { ReactComponent as DistributionImage } from './images/distribution.svg';
import { ReactComponent as MapImage } from './images/map.svg';
import StatisticsMapViCRI from './StatisticsMapViCRI';

import styles from './NationalViCRIBlock.module.scss';

export default function NationalViCRIBlock(props) {
	const {
		showDownloadScreenIcon = true,
		dataLayer = -1,
		countries,
		crimeIndexGeography,
		home,
		destination,
		destinationCoordinates,
		distributions,
		isLimitedVersion = false,
		homeCode,
		destinationCode,
	} = props;

	const [activeTab, setActiveTab] = useState('distribution');

	const elementToShare = useRef(null);

	const handleClickShare = () => {
		html2canvas(elementToShare.current).then((canvas) => {
			saveAs(canvas.toDataURL(), 'world-statistics.png');
		});
	};

	const headerTextOptions = {
		distribution: (
			<>
				National Violent Crime Risk Index (ViCRI) <TooltipVicri />
				<p>
					Country and Regional (where available) Rating for Homicide, Aggravated
					Assault, Robbery, and Rape.
				</p>
			</>
		),
		map: (
			<>
				National Violent Crime Risk Index (ViCRI)
				<p>
					Country and Regional (where available) Rating for Homicide, Aggravated
					Assault, Robbery, and Rape.
				</p>
			</>
		),
	};

	const headerText = headerTextOptions[activeTab];

	const existDistributionData =
		distributions && distributions.HR && distributions.HR.distribution.length;

	const showTabButtons = isLimitedVersion || existDistributionData;

	useEffect(() => {
		if (!showTabButtons) {
			setActiveTab('map');
		}
	}, [showTabButtons]);

	return (
		<div
			className={cn(styles['statistics-container-vicri'], 'section-wrap-white')}
			ref={elementToShare}
		>
			{dataLayer >= 0 && (
				<div className="data-layer-container">
					<DataLayer numData={dataLayer} />
				</div>
			)}
			<div className={styles.header}>
				<h4>{headerText}</h4>
				<div className={styles['block-right']}>
					{showDownloadScreenIcon && (
						<div className="download-icon" onClick={handleClickShare}>
							<Download className="icons-animation" />
						</div>
					)}

					{showTabButtons && (
						<div className={styles.buttons}>
							<TabButton
								text="Statistics"
								image={<DistributionImage />}
								onClick={() => setActiveTab('distribution')}
								isActive={activeTab === 'distribution'}
							/>
							<TabButton
								text="World map"
								image={<MapImage />}
								onClick={() => setActiveTab('map')}
								isActive={activeTab === 'map'}
							/>
							<div className={styles['tabs-line']} />
						</div>
					)}
				</div>
			</div>

			{activeTab === 'map' && (
				<StatisticsMapViCRI
					countries={countries}
					crimeIndexGeography={crimeIndexGeography}
					home={home}
					destination={destination}
					destinationCoordinates={destinationCoordinates}
				/>
			)}
			{activeTab === 'distribution' && (
				<StatisticsDistributionViCRI
					distributionLevel="country"
					distributions={distributions}
					homeCode={homeCode}
					destinationCode={destinationCode}
					isDemo={isLimitedVersion}
					showLabels
				/>
			)}
		</div>
	);
}
