import React from 'react';

import cn from '../../../../../lib/classNames';

import styles from './BarLabel.module.scss';

export default function BarLabel(props) {
	const {
		index = 0,
		x,
		y,
		width,
		height,
		fill,
		home,
		destination,
		showCountryCode = true,
	} = props;
	const caption = props?.data?.at(index)?.caption;

	if (!x || !y || !width || !height || !caption) return null;

	const locationImage = caption === 'home' ? 'home' : 'location_on';
	const location = caption === 'home' ? home : destination;
	const imageWidth = 20;
	const positionX = x + width / 2 - imageWidth;
	const positionY = 0;

	return (
		<g>
			<foreignObject
				className={styles['bar-label']}
				x={positionX}
				y={positionY}
			>
				<div className={styles['label-container']}>
					<div className={styles.line} style={{ borderColor: `${fill}` }} />
					<div className={styles.location}>
						<div
							className={cn(styles['location-icon'], 'material-icons-round')}
						>
							{locationImage}
						</div>
						{location && showCountryCode && (
							<div className={styles['location-text']}>{location}</div>
						)}
					</div>
				</div>
			</foreignObject>
		</g>
	);
}
