// @flow
import React, { Component, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import SvgIcon from 'react-svg-icon';
import get from 'get-value';
import moment from 'moment';
import qrcode from 'qrcode-generator';
import { CSSTransition } from 'react-transition-group';

import { Dialog } from '../../../components';
import LogotypeDark from '../../../components/LogotypeDark';
import RiskHighlightsIndicator from './RiskHighlightsIndicator';
import RiskScoreDecrease from './RiskScoreDecrease';
import RiskScoreIncrease from './RiskScoreIncrease';
import RiskScoreNoChange from './RiskScoreNoChange';
import RiskScoreLine from './RiskScoreLine';
import ContributorLine from './ContributorLine';
import RiskChart from './RiskChart';
import DataLayer from './DataLayer';
import Alert from './Alert';
import { VARIANT } from '../../Covid19/GeographicRisk';
import {
	homeIconPath,
	destinationIconPath,
	homeIconSvg,
	destinationIconSvg,
} from './SvgFactory';
import { fromToDetailsToString } from '../../../helpers/report';
import { TAG, keyConceptsAndTerminology } from '../../../lib/constants';
import {
	PersonalRiskDrivers,
	RiskRatingChart,
	GeographicRiskTrends,
	PersonalRisk,
	StatisticsMap,
	// StatisticsMapViCRI,
	GeographicRisk,
	GraphColor,
	ComparisionForPersonalRisk,
	ViolentCrimeRisk,
	TravelAdvice,
} from '../../Covid19';
import { api } from '../../../sagas';

import { roundNumber, getRoundingByCondition } from '../../../lib/';
import RiskSafetySummary from '../RiskSafetySummary/RiskSafetySummary';
import Loader from '../RiskSafetySummary/Loader.jsx';
import MenuMobile from './MenuMobile/MenuMobile';
import TravelerInfo from './TravelerInfo/TravelerInfo';
import NationalViCRIBlock from './NationalViCRIBlock/NationalViCRIBlock.jsx';

import attention from '../../../../images/icons/attention.svg';
import linkIcon from '../../../../images/icons/link.svg';
import iconHomeCircle from '../../../../images/icons/iconHomeCircle.svg';
import iconDestinationCircle from '../../../../images/icons/iconDestinationCircle.svg';

// TODO: need for test
// import mockData from './mockData/mockAIData1.json';
// import mockData from './mockData/mockAIData2.json';

import styles from './TravelReport.jss';
import CitiesViCRIBlock from './CitiesViCRIBlock/CitiesViCRIBlock.jsx';

export const DATE_FORMAT = 'MMM DD, YYYY';

const color = {
	relativeRiskIcon: 'rgb(35, 53, 69)',
	violet: '#9356F8',
};

export const RISK = {
	CRIME: 1,
	ACCIDENTS: 2,
	HEALTH: 3,
};

export const RISK_COLOR = {
	CRIME: 'rgb(255, 0, 0)',
	ACCIDENTS: 'rgb(126, 242, 243)',
	HEALTH: '#FFCB00',
};

const REPORT_NAV = [
	{
		id: 'riskScoring',
		btnText: 'RISK SCORING',
		title: 'Personal Risk Scoring and Effects of Travel Report',
		icon: '',
	},
	{
		id: 'riskBrief',
		btnText: 'RISK BRIEF',
		title: 'Risk Intelligence Brief for:',
		icon: 'auto_awesome',
	},
	// {
	// 	id: 'significantEvents',
	// 	btnText: 'SIGNIFICANT EVENTS',
	// 	title: 'SIGNIFICANT EVENTS',
	// 	icon: 'auto_awesome',
	// },
];

const BREAK_POINTS = {
	mobile: 'max-width: 767px',
	tablet: 'max-width: 1079px',
};

function getRiskColor(risk) {
	if (risk === RISK.CRIME) return RISK_COLOR.CRIME;
	else if (risk === RISK.ACCIDENTS) return RISK_COLOR.ACCIDENTS;
	else if (risk === RISK.HEALTH) return RISK_COLOR.HEALTH;
	return null;
}

type PropsType = {
	data: {},
	hideDashboard: boolean,
	hideShare: boolean,
	onClickPdf: Function,
	onClickShare: Function,
	onClickDashboard: Function,
	clickDashboardLabel: string,
};

function Tr({ title, data }) {
	if (!data) return null;
	return (
		<tr>
			<td className='title'>{title}</td>
			<td className='data'>{data}</td>
		</tr>
	);
}

class TravelReport extends Component<PropsType> {
	constructor(props: PropsType) {
		super(props);

		this.headerRef = React.createRef();

		this.state = {
			reportHelpOpened: false,
			reportHelpIndex: 0,
			reportActive: REPORT_NAV[0],
			isTablet: false,
			isMobile: false,
			aiReportData: this.props.data?.aiReport,
		};

		this.riskScoring = React.createRef();
		this.aiReport = React.createRef();

		this.svgs = { ...this.initialSvgs };
	}

	static defaultProps = {
		hideDashboard: false,
		hideShare: false,
	};

	initialSvgs = {
		riskScore: {
			crime: {
				scoreChange: null,
				chartHome: null,
				chartDestination: null,
				contributors: [],
				nonCommunicableContributors: [],
			},
			accidents: {
				scoreChange: null,
				chartHome: null,
				chartDestination: null,
				contributors: [],
				nonCommunicableContributors: [],
			},
			health: {
				scoreChange: null,
				chartHome: null,
				chartDestination: null,
				contributors: [],
				nonCommunicableContributors: [],
			},
		},
		riskWeighting: {
			home: null,
			destination: null,
		},
		riskChart: null,
		riskHighlightsIndicator: [],
		cdcNotices: {
			level1: { qr: [] },
			level2: { qr: [] },
			level3: { qr: [] },
		},
	};

	componentDidMount() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);

		this.checkWidth = () => {
			const match = (query) => window.matchMedia(`(${query})`).matches;

			this.setState({
				isTablet: match(BREAK_POINTS.tablet),
				isMobile: match(BREAK_POINTS.mobile),
			});
		};

		this.checkWidth();
		window.addEventListener('resize', this.checkWidth);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
		window.removeEventListener('resize', this.checkWidth);
	}

	handleOnClickPdf = () => {
		const { onClickPdf } = this.props;
		if (onClickPdf)
			onClickPdf({
				svgs: this.svgs,
			});
	};

	handleOnClickShare = () => {
		const { onClickShare } = this.props;
		if (onClickShare) onClickShare();
	};

	handleOnClickReportHelp = (idx) => {
		this.setState({
			reportHelpOpened: true,
			reportHelpIndex: idx,
		});
		return false;
	};

	handleOnCloseReportHelp = () => {
		this.setState({ reportHelpOpened: false });
		return false;
	};

	handleScroll = () => {
		const classHeaderShadow = this.props.classes.headerShadow;

		const isShowHeaderShadow =
			(document.documentElement.scrollTop || window.scrollY) > 2;

		if (!this.headerRef.current) {
			return;
		}

		const header = this.headerRef.current;

		if (isShowHeaderShadow && !header.classList.contains(classHeaderShadow)) {
			header.classList.add(classHeaderShadow);
		}

		if (!isShowHeaderShadow && header.classList.contains(classHeaderShadow)) {
			header.classList.remove(classHeaderShadow);
		}
	};

	handleClickDashboard = () => {
		const { onClickDashboard } = this.props;
		if (onClickDashboard) onClickDashboard();
	};

	getScoresMaxLevel = (score) => {
		const { data } = this.props;
		return get(data, `homeDestinationScoreDetails.scores.${score}.maxLevel`);
	};

	renderGuidanceDialog(): React.ReactNode {
		const { reportHelpOpened, reportHelpIndex } = this.state;
		const { data } = this.props;
		const main = get(data, `guidance.${reportHelpIndex}`);
		const { name, description, guidance } = main;
		const keys = Object.keys(guidance);

		return (
			<Dialog open={reportHelpOpened} onClose={this.handleOnCloseReportHelp}>
				<div>
					<Typography variant='h4'>{name}</Typography>
					<Typography variant='body1'>{description}</Typography>

					{keys.map((el, idx) => {
						let typeNum = 'risk-type risk-type-' + el;
						let typeGuidance = el + '. ' + guidance[el];

						return (
							<Typography className={typeNum} variant='body1' key={idx}>
								{typeGuidance}
							</Typography>
						);
					})}

					<Typography variant='body1'></Typography>
				</div>
			</Dialog>
		);
	}

	riskChartPointData(type: string, unique = false) {
		const { data } = this.props;
		if (data) {
			const { chartDetails } = data;
			if (chartDetails) {
				let newList = chartDetails
					.filter((el) => el.type === type)
					.map((el) => ({
						value: Math.round(parseFloat(el.score)),
						label: el.name,
					}));
				if (unique) {
					const values = newList.map((el) => el.value);
					const valuesUnique = Array.from(new Set(values));
					newList = valuesUnique.map((el) =>
						newList.find((p) => p.value === el)
					);
				}
				return newList;
			}
		}
		return [];
	}

	homeDestinationPointData(location) {
		if (this.riskChartPointData(location)[0]) {
			return this.riskChartPointData(location)[0];
		} else {
			if (location === 'home') {
				return {
					label: this.props.data.fromToDetails.home,
					value: -1,
				};
			} else {
				return {
					label: this.props.data.fromToDetails.destination,
					value: -1,
				};
			}
		}
	}

	renderRiskScore(data, risk, fullView = false): React.ReactNode {
		if (data && risk) {
			const { diff, homeScore, destinationScore, destinationContributors } =
				data;

			const nonCommunicableDestinationContributors = get(
				data,
				'nonCommunicableDestinationContributors',
				{ default: [] }
			);

			let contributorsTitle = '';
			let contributorsTitle1 = '';
			let contributorsTitle2 = '';
			let key = '';
			let bottomText = '';
			if (risk === RISK.CRIME) {
				contributorsTitle = 'Crime Risk Drivers';
				bottomText = 'Violent Crime Risk';
				key = 'crime';
			} else if (risk === RISK.ACCIDENTS) {
				contributorsTitle = 'Accidents Risk Drivers';
				bottomText = 'Accidents Risk';
				key = 'accidents';
			} else if (risk === RISK.HEALTH) {
				contributorsTitle = 'Health Risk Drivers';
				contributorsTitle1 = 'Non-communicable Drivers';
				contributorsTitle2 = 'Communicable Drivers';
				bottomText = 'Health Risk';
				key = 'health';
			}

			let scroreDiff = null;
			const diffAbs = Math.abs(diff);
			// const scoreDiffHeight = fullView ? 265 : 210;
			const scoreDiffHeight = fullView ? 150 : 150;
			if (diffAbs < 5)
				scroreDiff = (
					<RiskScoreNoChange
						key='diff'
						value={getRoundingByCondition(diff)}
						label='No significant change'
						bottomText={bottomText}
						height={scoreDiffHeight}
						onComplete={(props) => {
							this.svgs.riskScore[key].scoreChange = props;
						}}
					/>
				);
			else if (diff >= 0)
				scroreDiff = (
					<RiskScoreIncrease
						key='diff'
						value={getRoundingByCondition(diff)}
						label='Increase'
						bottomText={bottomText}
						height={scoreDiffHeight}
						onComplete={(props) => {
							this.svgs.riskScore[key].scoreChange = props;
						}}
					/>
				);
			else
				scroreDiff = (
					<RiskScoreDecrease
						key='diff'
						value={getRoundingByCondition(diff)}
						label='Decrease'
						height={scoreDiffHeight}
						bottomText={bottomText}
						onComplete={(props) => {
							this.svgs.riskScore[key].scoreChange = props;
						}}
					/>
				);

			const contributorsLabels = Object.keys(destinationContributors).splice(
				0,
				3
			);
			const nonCommunicableContributorsLabels = Object.keys(
				nonCommunicableDestinationContributors
			).splice(0, 3);

			if (fullView) {
				return [
					<div className='bg-white' key='RiskScoreLine'>
						<div key='diff' className='increase-decrease-full'>
							{scroreDiff}
						</div>
						<div
							key='score-line-contributors1'
							className='score-line-contributors'
						>
							<RiskScoreLine
								value={getRoundingByCondition(homeScore)}
								label='Residence'
								centerIcon={homeIconPath}
								color={getRiskColor(risk)}
								onComplete={(props) => {
									this.svgs.riskScore[key].chartHome = props;
								}}
							/>
							<RiskScoreLine
								value={getRoundingByCondition(destinationScore)}
								label='Destination'
								centerIcon={destinationIconPath}
								color={getRiskColor(risk)}
								onComplete={(props) => {
									this.svgs.riskScore[key].chartDestination = props;
								}}
							/>
						</div>
					</div>,
					<div
						className='bg-gray communicable-drivers'
						key='nonCommunicableContributorsLabels'
					>
						<div className='left-block'>
							<p className='riskDriversTitle'>{contributorsTitle1}</p>
							{nonCommunicableContributorsLabels.map((el, idx) => (
								<div className='riskDriversDataNarrow' key={idx}>
									<ContributorLine
										value={nonCommunicableDestinationContributors[el]}
										width={130}
										label={`${nonCommunicableDestinationContributors[el]}% ${el}`}
										onComplete={(props) => {
											this.svgs.riskScore[key].nonCommunicableContributors[
												idx
											] = props;
										}}
									/>
								</div>
							))}
						</div>

						<div key='score-line-contributors2' className='right-block'>
							<div>
								<p className='riskDriversTitle'>{contributorsTitle2}</p>
								{contributorsLabels.map((el, idx) => (
									<div className='riskDriversDataNarrow' key={idx}>
										<ContributorLine
											value={destinationContributors[el]}
											width={130}
											label={`${destinationContributors[el]}% ${el}`}
											onComplete={(props) => {
												this.svgs.riskScore[key].contributors[idx] = props;
											}}
										/>
									</div>
								))}
							</div>
						</div>
					</div>,
				];
			} else {
				return [
					<div className='bg-white' key='RiskScore'>
						<div key='diff' className='increase-decrease'>
							{scroreDiff}
						</div>
						<div key='scoreLines' className='risk-score-lines'>
							<RiskScoreLine
								value={getRoundingByCondition(homeScore)}
								label='Residence'
								centerIcon={homeIconPath}
								color={getRiskColor(risk)}
								onComplete={(props) => {
									this.svgs.riskScore[key].chartHome = props;
								}}
							/>
							<RiskScoreLine
								value={getRoundingByCondition(destinationScore)}
								label='Destination'
								centerIcon={destinationIconPath}
								color={getRiskColor(risk)}
								onComplete={(props) => {
									this.svgs.riskScore[key].chartDestination = props;
								}}
							/>
						</div>
					</div>,
					<div key='contributors' className='drivers bg-gray'>
						<p className='riskDriversTitle'>{contributorsTitle}</p>
						{contributorsLabels.map((el, idx) => (
							<div className='riskDriversData' key={idx}>
								<ContributorLine
									value={destinationContributors[el]}
									label={`${destinationContributors[el]}% ${el}`}
									onComplete={(props) => {
										this.svgs.riskScore[key].contributors[idx] = props;
									}}
								/>
							</div>
						))}
					</div>,
				];
			}
		}
		return null;
	}

	renderTravelAdvice() {
		const { classes, data } = this.props;
		const travelSupportInfo = get(data, 'travelSupportInfo');
		const countryCode = travelSupportInfo?.code2;

		return countryCode ? (
			<TravelAdvice travelSupportInfo={travelSupportInfo} />
		) : null;
	}

	renderHealthNotices() {
		const { classes } = this.props;
		const level1 = get(this.props, 'data.cdcNotices.LEVEL_1', {
			default: [],
		});
		const level2 = get(this.props, 'data.cdcNotices.LEVEL_2', {
			default: [],
		});
		const level3 = get(this.props, 'data.cdcNotices.LEVEL_3', {
			default: [],
		});

		if (level1.length || level2.length || level3.length) {
			const components = [];
			components.push(
				<div className='titleSection' key='titleSection'>
					<Typography key='header' variant='h4' align='left'>
						Travel Health Notices
					</Typography>
					<Typography key='sub-header' variant='h5' align='left'>
						Department of State Safety and Security Alerts
					</Typography>
				</div>
			);
			if (level3.length) {
				components.push(
					<div
						key='level3'
						className={`${classes.healthNotice} ${classes.healthNoticeLevel3}`}
					>
						<div className='notice-header'>
							<img src={attention} alt='' />{' '}
							<span>Warning Level 3, Avoid Nonessential Travel</span>
						</div>
						<div className='content'>
							{level3.map((el, index) => {
								const title = get(el, 'title');
								const description = get(el, 'description');
								const url = get(el, 'sourceUrl');
								let date = get(el, 'datePublished');
								if (date) date = moment(date).format(DATE_FORMAT);
								const qr = qrcode(0, 'M');
								qr.addData(url);
								qr.make();
								this.svgs.cdcNotices.level3.qr[index] = qr.createSvgTag();
								return (
									<div className='notice-item' key={index}>
										<div className='row top-block'>
											<div className='left-block'>
												<div className='title'>{title}</div>
												<div className='date'>{date}</div>
												<p className='description'>{description}</p>
											</div>
											<div className='right-block qrcode'>
												<img src={qr.createDataURL()} alt='QRC' />
											</div>
										</div>
										<div className='row bottom-block'>
											<div className='url'>
												<a href={url}>
													<img src={linkIcon} alt='' /> {url}
												</a>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			}
			if (level2.length) {
				components.push(
					<div
						key='level2'
						className={`${classes.healthNotice} ${classes.healthNoticeLevel2}`}
					>
						<div className='notice-header'>
							<img src={attention} alt='' />{' '}
							<span>Alert Level 2, Practice Enhanced Precautions</span>
						</div>
						<div className='content'>
							{level2.map((el, index) => {
								const title = get(el, 'title');
								const description = get(el, 'description');
								const url = get(el, 'sourceUrl');
								let date = get(el, 'datePublished');
								if (date) date = moment(date).format(DATE_FORMAT);
								const qr = qrcode(0, 'M');
								qr.addData(url);
								qr.make();
								this.svgs.cdcNotices.level2.qr[index] = qr.createSvgTag();
								return (
									<div className='notice-item' key={index}>
										<div className='row top-block'>
											<div className='left-block'>
												<div className='title'>{title}</div>
												<div className='date'>{date}</div>
												<p className='description'>{description}</p>
											</div>
											<div className='right-block qrcode'>
												<img src={qr.createDataURL()} alt='QRC' />
											</div>
										</div>
										<div className='row bottom-block'>
											<div className='url'>
												<a href={url}>
													<img src={linkIcon} alt='' /> {url}
												</a>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			}
			if (level1.length) {
				components.push(
					<div
						key='level1'
						className={`${classes.healthNotice} ${classes.healthNoticeLevel1}`}
					>
						<div className='notice-header'>
							<img src={attention} alt='' />{' '}
							<span>Watch Level 1, Practice Enhanced Precautions</span>
						</div>
						<div className='content'>
							{level1.map((el, index) => {
								const title = get(el, 'title');
								const description = get(el, 'description');
								const url = get(el, 'sourceUrl');
								let date = get(el, 'datePublished');
								if (date) date = moment(date).format(DATE_FORMAT);
								const qr = qrcode(0, 'M');
								qr.addData(url);
								qr.make();
								this.svgs.cdcNotices.level1.qr[index] = qr.createSvgTag();
								return (
									<div className='notice-item' key={index}>
										<div className='row top-block'>
											<div className='left-block'>
												<div className='title'>{title}</div>
												<div className='date'>{date}</div>
												<p className='description'>{description}</p>
											</div>
											<div className='right-block qrcode'>
												<img src={qr.createDataURL()} alt='QRC' />
											</div>
										</div>
										<div className='row bottom-block'>
											<div className='url'>
												<a href={url}>
													<img src={linkIcon} alt='' /> {url}
												</a>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				);
			}
			return components;
		}
		return null;
	}

	render(): React.ReactNode {
		const { classes, data, hideDashboard, hideShare, clickDashboardLabel } =
			this.props;
		if (!data) return null;

		const {
			additionalRisks,
			fromToDetails,
			homeDestinationScoreDetails,
			covid,
			crimeIndex,
			homeCircumstances,
			destinationCircumstances,
			nationalCrimeIndexGeography,
			crimeIndexGeography,
			crimeHRBarChart,
			crimeVCRBarChart,
			crimeIndexBarChart,
			crimeBarCharts,
		} = data;

		// const additionalRisksSorted = [...additionalRisks].sort((a, b) => {
		// 	// First, sort by rating in descending order
		// 	if (b.rating !== a.rating) {
		// 		return b.rating - a.rating;
		// 	}
		// 	// If ratings are equal, sort by name in ascending order
		// 	return a.name.localeCompare(b.name);
		// });

		const distributionsCountry = crimeBarCharts
			? {
					HR: crimeBarCharts.country.HR,
					VCR: crimeBarCharts.country.VCR,
					Index: crimeBarCharts.country.Index,
			  }
			: {
					HR: crimeHRBarChart,
					VCR: crimeVCRBarChart,
					Index: crimeIndexBarChart,
			  };

		const cityLevelDataExist = crimeBarCharts;

		// TODO: temp for testing!
		// const cityLevelDataExist = true;

		// const distributionsPlace = {
		// 	HR: crimeHRBarChart,
		// 	VCR: crimeVCRBarChart,
		// 	Index: crimeIndexBarChart,
		// };

		const distributionsPlace = {
			HR: crimeBarCharts?.place?.HR,
			VCR: crimeBarCharts?.place?.VCR,
			Index: crimeBarCharts?.place?.Index,
		};

		// TODO: temp for testing!
		// const distributionsPlace = {
		// 	HR: crimeHRBarChart,
		// 	VCR: crimeVCRBarChart,
		// 	Index: crimeIndexBarChart,
		// };

		const destinationCoordinates = {
			lat: data?.destinationBoundaryMetaLat || data?.covid.destination.covidLat,
			lng: data?.destinationBoundaryMetaLng || data?.covid.destination.covidLng,
		};

		const {
			scores: { health, crime, accidents },
			ratio,
			riskContributors,
		} = homeDestinationScoreDetails;

		// TODO: need to remove it all after data and style removing
		const {
			home: covidHome,
			destination: covidDestination,
			comparisonImpact: covidComparisonImpact,
		} = covid;

		const { home: crimeIndexHome, destination: crimeIndexDestination } =
			crimeIndex;

		const destinationLabel = fromToDetailsToString(fromToDetails);
		const headerClasses = [classes.header];

		// TODO: need to remove it all after data and style removing

		// const riskDeath = get(covidHome, 'covidDeathRate');

		// const mmRiskDeathVal = roundNumber(riskDeath.value, 0);
		// const mmRiskDeathLower = roundNumber(riskDeath.lower, 0);
		// const mmRiskDeathUupper = roundNumber(riskDeath.upper, 0);

		// const actualVal = getRoundingByCondition(
		// 	get(covidComparisonImpact, 'deltaActualCommunicableDiseaseRate.value')
		// );

		// const actualLower = roundNumber(
		// 	get(covidComparisonImpact, 'deltaActualCommunicableDiseaseRate.lower'),
		// 	0
		// );
		// const actualUpper = roundNumber(
		// 	get(covidComparisonImpact, 'deltaActualCommunicableDiseaseRate.upper'),
		// 	0
		// );

		// const covidDeathVal = roundNumber(
		// 	get(covidComparisonImpact, 'deltaCovidDeathRate.value'),
		// 	0
		// );

		// const covidDeathRateComparableVal = getRoundingByCondition(
		// 	get(covidComparisonImpact, 'deltaCovidDeathRateComparable.value')
		// );

		// const covidDeathLower = roundNumber(
		// 	get(covidComparisonImpact, 'deltaCovidDeathRate.lower'),
		// 	0
		// );

		// const covidDeathUpper = roundNumber(
		// 	get(covidComparisonImpact, 'deltaCovidDeathRate.upper'),
		// 	0
		// );

		// const legacyVal = getRoundingByCondition(
		// 	get(covidComparisonImpact, 'deltaLegacyCommunicableDiseaseRate.value')
		// );

		// const legacyLower = roundNumber(
		// 	get(covidComparisonImpact, 'deltaLegacyCommunicableDiseaseRate.lower'),
		// 	0
		// );
		// const legacyUpper = roundNumber(
		// 	get(covidComparisonImpact, 'deltaLegacyCommunicableDiseaseRate.upper'),
		// 	0
		// );

		// const destinationVal = getRoundingByCondition(
		// 	get(covidComparisonImpact, 'destinationCovidDeathRate.value')
		// );

		// const destinationLower = roundNumber(
		// 	get(covidComparisonImpact, 'destinationCovidDeathRate.lower'),
		// 	0
		// );
		// const destinationUpper = roundNumber(
		// 	get(covidComparisonImpact, 'destinationCovidDeathRate.upper'),
		// 	0
		// );

		const dashboardLabel = clickDashboardLabel ? clickDashboardLabel : 'BACK';

		const mapCenter = [-97.9222112121185, 39.3812661305678];
		const riskLabel = null;
		const mapPersonalStyle = { width: '100%', height: 160 };

		const classColor = (diff) => {
			if (Math.abs(diff) < 5) {
				return '';
			} else {
				return diff > 0 ? 'increase' : 'decrease';
			}
		};

		// const covidHomeLocation = get(covidHome, 'covidRankingLocation');
		// const covidDestinationLocation = get(
		// 	covidDestination,
		// 	'covidRankingLocation'
		// );

		const circumstances = (value, location) => {
			if (value && location === 'home') {
				return homeCircumstances.includes(value);
			} else if (value && location === 'destination') {
				return destinationCircumstances.includes(value);
			}

			return;
		};

		const isConflictHome = circumstances('MILITARY_CONFLICT', 'home');
		const isNoDataHome = circumstances('NO_DATA', 'home');
		const isConflictDestination = circumstances(
			'MILITARY_CONFLICT',
			'destination'
		);
		const isNoDataDestination = circumstances('NO_DATA', 'destination');

		const getAiReport = () => {
			const { data } = this.props;
			const aiReportData = this.state.aiReportData;

			if (!aiReportData && data?.id) {
				api.getReport(data.id).then((response) => {
					if (response.ok && response.data?.aiReport) {
						this.setState({ aiReportData: response.data?.aiReport });
					}
				});
			}
		};

		const renderReport = () => {
			const reportActive = this.state.reportActive;

			const aiReportData = this.state.aiReportData;
			// TODO: need for test
			// const aiReportData = mockData;

			const isRiskScoring =
				REPORT_NAV[0] && reportActive.id === REPORT_NAV[0].id;

			const isAIReport = REPORT_NAV[1] && reportActive.id === REPORT_NAV[1].id;

			if (isAIReport && !aiReportData) {
				getAiReport();
				return (
					<CSSTransition
						in={!aiReportData}
						appear={true}
						timeout={100}
						classNames='loader'
						unmountOnExit
					>
						<Loader />
					</CSSTransition>
				);
			}

			return (
				<>
					<CSSTransition
						in={isAIReport}
						appear={true}
						timeout={100}
						classNames='risk-safety-summary'
						unmountOnExit
					>
						<RiskSafetySummary
							data={aiReportData}
							locationCenter={[
								Number(data?.fromToDetails?.destinationLng),
								Number(data?.fromToDetails?.destinationLat),
							]}
							showWarningMessage={isConflictDestination}
							destination={fromToDetails.destination}
						/>
					</CSSTransition>
					<CSSTransition
						in={isRiskScoring}
						appear={true}
						timeout={100}
						classNames={{
							enter: 'risk-scoring-enter',
							enterActive: 'risk-scoring-active-enter',
							enterDone: 'risk-scoring-done-enter',
							exit: 'risk-scoring-exit',
							exitActive: 'risk-scoring-active-exit',
							exitDone: 'risk-scoring-done-exit',
						}}
						unmountOnExit
					>
						<div className={classes.riskScoringContainer}>
							<div
								className={`${classes.homeDestinationsLocation} styles-mob-767`}
							>
								<div className='block-location block-home block-left'>
									<div className='location-info'>
										<div className='left'>
											<div className='icon'>
												{homeIconSvg({
													fill: color.relativeRiskIcon,
													width: 27,
												})}
											</div>
											<div className='title'>Residence</div>
											<div className='location-name'>{fromToDetails.home}</div>
										</div>
										<div className='right location-info'>
											<table cellSpacing='0' cellPadding='0' border='0'>
												<tbody>
													<Tr title='Country' data={fromToDetails.homeLevel0} />
													<Tr
														title='State/Region'
														data={fromToDetails.homeLevel1}
													/>
													<Tr title='County' data={fromToDetails.homeLevel2} />
													<Tr title='City' data={fromToDetails.homeLevel3} />
												</tbody>
											</table>
										</div>
									</div>
									<div className='location-map'>
										<GeographicRisk
											location={[
												parseFloat(fromToDetails.homeLng),
												parseFloat(fromToDetails.homeLat),
											]}
											variant={VARIANT.COMPARE}
										/>
									</div>
								</div>
								<div className='block-location block-destination block-right'>
									<div className='location-info'>
										<div className='left'>
											<div className='icon'>
												{destinationIconSvg({
													fill: color.relativeRiskIcon,
													width: 22,
												})}
											</div>
											<div className='title'>Travel Destination</div>
											<div className='location-name'>
												{fromToDetails.destination}
											</div>
										</div>
										<div className='right location-info'>
											<table cellSpacing='0' cellPadding='0' border='0'>
												<tbody>
													<Tr
														title='Country'
														data={fromToDetails.destinationLevel0}
													/>
													<Tr
														title='State/Region'
														data={fromToDetails.destinationLevel1}
													/>
													<Tr
														title='County'
														data={fromToDetails.destinationLevel2}
													/>
													<Tr
														title='City'
														data={fromToDetails.destinationLevel3}
													/>
												</tbody>
											</table>
										</div>
									</div>
									<div className='location-map'>
										<GeographicRisk
											location={[
												parseFloat(fromToDetails.destinationLng),
												parseFloat(fromToDetails.destinationLat),
											]}
											variant={VARIANT.COMPARE}
										/>
									</div>
								</div>
							</div>

							{!cityLevelDataExist && (
								<>
									<div className='titleSection titleViolentCrimeRiskIndex'>
										<Typography variant='h4' align='left'>
											Violent Crime Risk Rating
										</Typography>
										<Typography variant='h5' align='left'>
											City ranking according to the Violent Crime Risk (ViCRI)
											Index for Global Cities
										</Typography>
									</div>

									<div
										className={`${classes.violentCrimeRiskIndex} styles-mob-767`}
									>
										<ViolentCrimeRisk
											locationHome={
												crimeIndexHome.location || fromToDetails.home
											}
											locationDestination={
												crimeIndexDestination.location ||
												fromToDetails.destination
											}
											riskValueHome={crimeIndexHome.index}
											riskValueDestination={crimeIndexDestination.index}
											homeCrimeRiskIndexLocations={crimeIndexHome.locations}
											destinationCrimeRiskIndexLocations={
												crimeIndexDestination.locations
											}
											isConflictHome={isConflictHome}
											isConflictDestination={isConflictDestination}
											isNoDataHome={isNoDataHome}
											isNoDataDestination={isNoDataDestination}
										/>
									</div>
								</>
							)}

							{/* TODO: need to actualize data */}
							<div className={classes.covidHeatmap}>
								<NationalViCRIBlock
									dataLayer={-1}
									showDownloadScreenIcon={false}
									countries={nationalCrimeIndexGeography}
									crimeIndexGeography={crimeIndexGeography}
									home={{
										countryCode: get(covidHome, 'countryCode'),
										countryCircumstance: get(covidHome, 'countryCircumstance'),
										countryCrimeBucket: get(covidHome, 'countryCrimeBucket'),
									}}
									// home={{
									// 	countryCode: fromToDetails?.destinationCountryCode || '-',
									// 	countryCircumstance: homeCircumstances,
									// 	countryCrimeBucket: crimeIndexBarChart?.homeValue || 0,
									// }}
									destination={{
										countryCode: get(covidDestination, 'countryCode'),
										countryCircumstance: get(
											covidDestination,
											'countryCircumstance'
										),
										countryCrimeBucket: get(
											covidDestination,
											'countryCrimeBucket'
										),
									}}
									// destination={{
									// 	countryCode: fromToDetails?.homeCountryCode || '-',
									// 	countryCircumstance: destinationCircumstances,
									// 	countryCrimeBucket:
									// 		crimeIndexBarChart?.destinationValue || 0,
									// }}
									destinationCoordinates={destinationCoordinates}
									distributions={distributionsCountry}
									isLimitedVersion={false}
									homeCode={fromToDetails?.homeCountryCode}
									destinationCode={fromToDetails?.destinationCountryCode}
								/>
							</div>

							{cityLevelDataExist && (
								<CitiesViCRIBlock
									showDownloadScreenIcon={false}
									distributions={distributionsPlace}
									isLimitedVersion={false}
									homeCode={fromToDetails?.homeCountryCode}
									destinationCode={fromToDetails?.destinationCountryCode}
									locationHome={crimeIndexHome?.location || fromToDetails?.home}
									locationDestination={
										crimeIndexDestination?.location ||
										fromToDetails?.destination
									}
									riskValueHome={crimeIndexHome?.index}
									riskValueDestination={crimeIndexDestination?.index}
									homeCrimeRiskIndexLocations={crimeIndexHome?.locations}
									destinationCrimeRiskIndexLocations={
										crimeIndexDestination?.locations
									}
									isConflictHome={isConflictHome}
									isConflictDestination={isConflictDestination}
									isNoDataHome={isNoDataHome}
									isNoDataDestination={isNoDataDestination}
								/>
							)}

							<div className='titleSection'>
								<Typography variant='h4' align='left'>
									Personalized Risk Scores Estimates
								</Typography>
								<Typography variant='h5' align='left'>
									Underlying risk rates compared to home region
								</Typography>
							</div>
							{(isConflictHome || isConflictDestination) && (
								<div className={classes.alertSection}>
									<Alert />
								</div>
							)}

							<div className={`${classes.riskScoresEstimates} styles-mob-1024`}>
								<div
									className={`${classes.riskScoresContainer} ${classColor(
										homeDestinationScoreDetails.scores.crime.diff
									)}`}
								>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer numData={this.getScoresMaxLevel('crime')} />
									</div>
									{this.renderRiskScore(crime, RISK.CRIME)}
								</div>
								<div
									className={`${classes.riskScoresContainer} ${classColor(
										homeDestinationScoreDetails.scores.accidents.diff
									)}`}
								>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer numData={this.getScoresMaxLevel('accidents')} />
									</div>
									{this.renderRiskScore(accidents, RISK.ACCIDENTS)}
								</div>
								<div
									className={`${classes.riskScoresContainer} ${classColor(
										homeDestinationScoreDetails.scores.health.diff
									)}`}
								>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer numData={this.getScoresMaxLevel('health')} />
									</div>
									{this.renderRiskScore(health, RISK.HEALTH, true)}
								</div>
							</div>

							<div className='titleSection'>
								<Typography variant='h4' align='left'>
									Relative Risk Weighting
								</Typography>
								<Typography variant='h5' align='left'>
									By risk domain
								</Typography>
							</div>
							<div
								className={`${classes.relativeRiskContainer} styles-mob-767`}
							>
								<div className='relative-risk relative-risk-home block-left'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={
												this.props.data?.homeDestinationScoreDetails
													?.ratioMaxLevel?.home
											}
										/>
									</div>
									<div>
										<span className='labelContainer'>
											<span className='label subTitle'>Home</span>
										</span>
									</div>
									<PersonalRiskDrivers
										data={{
											crime: {
												label: get(riskContributors, 'home.crime', {
													default: '',
												}),
												value: get(ratio, 'home.crime', {
													default: 0,
												}),
											},
											accidents: {
												label: get(riskContributors, 'home.accidents', {
													default: '',
												}),
												value: get(ratio, 'home.accidents', {
													default: 0,
												}),
											},
											health: {
												label: get(riskContributors, 'home.health', {
													default: '',
												}),
												value: get(ratio, 'home.health', {
													default: 0,
												}),
											},
										}}
										location={fromToDetails.home}
										centerIcon={homeIconPath}
									/>
								</div>
								<div className='relative-risk relative-risk-destination block-right'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={
												this.props.data?.homeDestinationScoreDetails
													?.ratioMaxLevel?.destination
											}
										/>
									</div>
									<div>
										<span className='labelContainer'>
											<span className='label subTitle'>Travel Destination</span>
										</span>
									</div>
									<PersonalRiskDrivers
										data={{
											crime: {
												label: get(riskContributors, 'destination.crime', {
													default: '',
												}),
												value: get(ratio, 'destination.crime', {
													default: 0,
												}),
											},
											accidents: {
												label: get(riskContributors, 'destination.accidents', {
													default: '',
												}),
												value: get(ratio, 'destination.accidents', {
													default: 0,
												}),
											},
											health: {
												label: get(riskContributors, 'destination.health', {
													default: '',
												}),
												value: get(ratio, 'destination.health', {
													default: 0,
												}),
											},
										}}
										location={fromToDetails.destination}
										centerIcon={destinationIconPath}
									/>
								</div>
							</div>

							<div className={classes.crimeRiskLineContainer}>
								<div className='titleSection'>
									<Typography variant='h4' align='left'>
										Crime Risk Score Reference
									</Typography>
									<Typography variant='h5' align='left'>
										Underlying incident/victimization rates per 100,000
									</Typography>
								</div>
								<div className='crime-risk-graphic-container'>
									<RiskChart
										pointData={this.riskChartPointData('popular', true)}
										homePointData={this.homeDestinationPointData('home')}
										destinationPointData={this.homeDestinationPointData(
											'destination'
										)}
										onComplete={(props) => {
											this.svgs.riskChart = props;
										}}
										width={770}
										height={380}
									/>
								</div>
								<div className={classes.clear} />
							</div>
							<div className='titleSection additionalRiskHighlights'>
								<Typography variant='h4' align='left'>
									Additional Risk Highlights &amp; Indicators{' '}
								</Typography>
								<Typography variant='h5' align='left'>
									in {fromToDetails.destinationLevel0}
								</Typography>
							</div>
							<div className={classes.riskHighlightsIndicatorContainer}>
								{/* {additionalRisksSorted.map((el, idx) => ( */}
								{additionalRisks.map((el, idx) => (
									<div
										key={idx}
										className='risk-highlights-indicator'
										onClick={() => this.handleOnClickReportHelp(idx)}
									>
										<div>
											<RiskHighlightsIndicator
												name={el.name}
												value={el.rating}
												description={el.ratingDescription}
											/>
										</div>
									</div>
								))}
							</div>
							<div className={classes.travelHealthNotices}>
								{this.renderHealthNotices()}
							</div>
							<div className={classes.travelAdviceContainer}>
								{this.renderTravelAdvice()}
							</div>

							{/* TODO: need to remove it all after data and style removing */}
							{/* TODO: remove covid content. Start.*/}
							{/* <div className='titleSection'>
								<Typography variant='h4' align='left'>
									COVID-19 Geographic Risk
								</Typography>
								<Typography variant='h5' align='left'>
									Underlying risk rates compared to home region
								</Typography>
							</div>
							<div className={`${classes.covidGeographicRisk} styles-mob-767`}>
								<div className='block-left box-graph'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={this.props.data?.covid?.home?.covidMaxLevel}
										/>
									</div>
									<Typography variant='h4' align='left'>
										COVID-19 Geographic Risk Rating
									</Typography>
									<div className='graph-top'>
										<RiskRatingChart
											value={covidHome.covidBucket}
											text={covidHome.covidRankingGroup}
											colors='blue'
										/>
										<div className='block-text-wrap'>
											<span className='block-text-state block-text-state-low'>
												Low Risk
											</span>
											<div
												className='block-text-location'
												title={covidHome.covidRankingLocation}
											>
												{covidHome.covidRankingLocation}
											</div>
											<span className='block-text-state block-text-state-high'>
												High Risk
											</span>
										</div>
										<div className='block-icon-location'>
											<img src={iconHomeCircle} alt='' />
										</div>
									</div>
								</div>
								<div className='block-right box-graph'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={
												this.props.data?.covid?.destination?.covidMaxLevel
											}
										/>
									</div>
									<Typography variant='h4' align='left'>
										COVID-19 Geographic Risk Rating
									</Typography>
									<div className='graph-top'>
										<RiskRatingChart
											value={covidDestination.covidBucket}
											text={covidDestination.covidRankingGroup}
											colors='blue'
										/>
										<div className='block-text-wrap'>
											<span className='block-text-state block-text-state-low'>
												Low Risk
											</span>
											<div
												className='block-text-location'
												title={covidDestination.covidRankingLocation}
											>
												{covidDestination.covidRankingLocation}
											</div>
											<span className='block-text-state block-text-state-high'>
												High Risk
											</span>
										</div>
										<div className='block-icon-location'>
											<img src={iconDestinationCircle} alt='' />
										</div>
									</div>
								</div>
							</div> */}

							{/* <div
								className={`${classes.covidGeographicRiskTrends} styles-mob-767`}
							>
								<div className='col block-left'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={this.props.data?.covid?.home?.covidTrendMaxLevel}
										/>
									</div>
									<GeographicRiskTrends
										casesTrend={get(covidHome, 'covidCasesTrend')}
										deathTrend={get(covidHome, 'covidDeathTrend')}
										covidTrendHistory={get(covidHome, 'covidTrendHistory')}
										location={get(covidHome, 'covidRankingLocation')}
										locationIcon={iconHomeCircle}
									/>
								</div>
								<div className='col block-right'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={
												this.props.data?.covid?.destination?.covidTrendMaxLevel
											}
										/>
									</div>
									<GeographicRiskTrends
										casesTrend={get(covidDestination, 'covidCasesTrend')}
										deathTrend={get(covidDestination, 'covidDeathTrend')}
										covidTrendHistory={get(
											covidDestination,
											'covidTrendHistory'
										)}
										location={get(covidDestination, 'covidRankingLocation')}
										locationIcon={iconDestinationCircle}
									/>
								</div>
							</div> */}

							{/* <div
								className={`${classes.covidPersonalRiskImpact} styles-mob-767`}
							>
								<div className='block-left'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={this.props.data?.covid?.home?.riskRateMaxLevel}
										/>
									</div>
									<div className='header'>
										<img src={iconHomeCircle} alt='' />
										<div className='block-text'>
											<h3>Personal Risk Impact of COVID-19</h3>
											<span>{get(covidHome, 'covidLocation')}</span>
											<span className='sub-line'>
												Increase of mortality risk compared to legacy death
												rates for NON VACCINATED individuals (by cause; based on
												recent COVID-19 deaths for selected location and
												demographic group).
											</span>
										</div>
									</div>
									<div className='graph-wrp'>
										<div className='graph-col graph-col-0'>
											<PersonalRisk
												riskChange={get(covidHome, 'riskRateChangeAll')}
												riskChangeHealth={get(
													covidHome,
													'riskRateChangeHealth'
												)}
												riskChangeCommunicableHealth={get(
													covidHome,
													'riskRateChangeCommunicableHealth'
												)}
												location={get(covidHome, 'covidRankingLocation')}
												tipDefaultOpen={1}
												locationIcon={iconHomeCircle}
												listCausesIdx={0}
											/>
										</div>
										<div className='graph-col graph-col-1'>
											<PersonalRisk
												riskChange={get(covidHome, 'riskRateChangeAll')}
												riskChangeHealth={get(
													covidHome,
													'riskRateChangeHealth'
												)}
												riskChangeCommunicableHealth={get(
													covidHome,
													'riskRateChangeCommunicableHealth'
												)}
												location={get(covidHome, 'covidRankingLocation')}
												tipDefaultOpen={1}
												locationIcon={iconHomeCircle}
												listCausesIdx={1}
											/>
										</div>
										<div className='graph-col graph-col-2'>
											<PersonalRisk
												riskChange={get(covidHome, 'riskRateChangeAll')}
												riskChangeHealth={get(
													covidHome,
													'riskRateChangeHealth'
												)}
												riskChangeCommunicableHealth={get(
													covidHome,
													'riskRateChangeCommunicableHealth'
												)}
												location={get(covidHome, 'covidRankingLocation')}
												tipDefaultOpen={1}
												locationIcon={iconHomeCircle}
												listCausesIdx={2}
											/>
										</div>
									</div>
									<div className='graph-color'>
										<div className='graph-color-item graph-color-item-01'>
											<GraphColor className='graph-color-violet' />
											<div className='graph-color-text'>COVID19 Impact</div>
										</div>
									</div>
								</div>
								<div className='block-right'>
									<div className='wrp'>
										<div className='cont'>
											<h4>Mortality rate estimate</h4>
											<div className='per'>per 100K</div>
											<div className='data'>{mmRiskDeathVal}</div>
											<div className='ci'>
												{mmRiskDeathLower}-{mmRiskDeathUupper} CI
											</div>
										</div>
										<div className='footer'>
											Estimated COVID-19 annual mortality rates based on current
											deaths for selected demographic (not a cumulative
											estimate)
										</div>
									</div>
								</div>
							</div> */}

							{/* <div
								className={`${classes.covidComparisonPersonalRiskImpact} styles-mob-767`}
							>
								<div className='block-left'>
									<div className='header'>
										<div className='block-text'>
											<h3>Comparison for Personal Risk Impact of COVID-19</h3>
											<span>
												{covidHomeLocation} vs {covidDestinationLocation}
											</span>
										</div>
									</div>
									<div className='graph-wrp'>
										<div className='graph-block graph-block-home'>
											<div className='icon-location'>
												<img src={iconHomeCircle} alt='' />
											</div>
											<div className='title-location'>{covidHomeLocation}</div>
											<div className='arrow arrow-down'></div>
										</div>
										<div>
											<ComparisionForPersonalRisk
												riskRateChangeCommunicableHealth={get(
													covidHome,
													'riskRateChangeCommunicableHealth.value'
												)}
												deltaLegacyCommunicableDiseaseRate={get(
													covidComparisonImpact,
													'deltaLegacyCommunicableDiseaseRate.value'
												)}
												deltaCovidDeathRate={get(
													covidComparisonImpact,
													'deltaCovidDeathRate.value'
												)}
											/>
										</div>
										<div className='graph-block graph-block-destination'>
											<div className='arrow arrow-up'></div>
											<div className='title-location'>
												{covidDestinationLocation}
											</div>
											<div className='icon-location '>
												<img src={iconDestinationCircle} alt='' />
											</div>
										</div>
									</div>
									<div className='graph-color'>
										<div className='graph-color-item graph-color-item-01'>
											<GraphColor className='graph-color-violet' />
											<div className='graph-color-text'>
												<div className='title'>MRc19</div>
												<div className='description'>
													Mortality Risk of COVID-19.
												</div>
												<div className='description'>
													* Only applicable to NON VACCINATED individuals
												</div>
											</div>
										</div>
										<div className='graph-color-item graph-color-item-01'>
											<GraphColor className='graph-color-health' />
											<div className='graph-color-text'>
												<div className='title'>(L)MRc</div>
												<div className='description'>
													Legacy mortality risk from all other communicable
													diseases (including the flu, HIV/AIDS, yellow fever,
													TB, etc.)
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='block-right'>
									<div className={`${classes.dataLayerContainer}`}>
										<DataLayer
											numData={
												this.props.data?.covid?.comparisonImpact?.maxLevel
											}
										/>
									</div>
									<div className='wrp'>
										<div className='cont'>
											<div className='graph-info'>
												<div className='graph-info-item graph-info-item-01'>
													<div className='box-data'>
														<GraphColor className='graph-color-violet' />
														<div className='data'>
															{destinationVal} <span>per 100K</span>
														</div>
													</div>
													<div className='ci'>
														{destinationLower}-{destinationUpper} CI
													</div>
													<div className='text'>
														Estimated COVID-19 annual mortality rates based on
														current deaths for selected demographic (not a
														cumulative estimate)
													</div>
												</div>
												<div className='graph-info-item graph-info-item-02'>
													<div className={`${classes.dataLayerContainer}`}>
														<DataLayer
															numData={
																this.props.data?.covid?.comparisonImpact
																	?.maxLevelComparable
															}
														/>
													</div>
													<div className='box-data'>
														<GraphColor className='graph-color-violet-dark' />
														<div className='data'>
															{covidDeathRateComparableVal}% <span>ΔMRc19</span>
														</div>
													</div>
													<div className='text'>
														Estimated difference (delta) of mortality risk from
														COVID-19 at destination vs. home
													</div>
												</div>
												<div className='graph-info-item graph-info-item-03'>
													<div className='box-data'>
														<GraphColor className='graph-color-gradient-violet-yellow' />
														<div className='data'>
															{actualVal}% <span>ΔMRc</span>
														</div>
													</div>
													<div className='text'>
														Estimated difference (delta) of mortality risk from
														communicable diseases (including COVID-19) at
														destination for the traveler compared to home. Based
														on annual rates projections from current COVID-19
														deaths and other/legacy infectious diseases
														(including the flu, HIV/AIDS, tuberculosis, etc.)
													</div>
												</div>
												<div className='graph-info-item graph-info-item-04'>
													<div className='box-data'>
														<GraphColor className='graph-color-yellow-dark' />
														<div className='data'>
															{legacyVal}% <span>Legacy ΔMRc</span>
														</div>
													</div>
													<div className='text'>
														Estimated difference (delta) of risk of death from
														all communicable diseases (excluding COVID-19) at
														destination for the traveler compared to home
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div> */}

							{/* <div className={classes.covidHeatmap}>
								<StatisticsMap
									countries={get(covid, 'covidGeography')}
									home={{
										countryCode: get(covidHome, 'countryCode'),
										covidBucket: get(covidHome, 'countryCovidBucket'),
									}}
									destination={{
										countryCode: get(covidDestination, 'countryCode'),
										covidBucket: get(covidDestination, 'countryCovidBucket'),
									}}
									headerText={
										<>
											Confirmed COVID-19 Death per Million People
											<p>World</p>
										</>
									}
								/>
							</div> */}
							{/* TODO: remove covid content. Finish.*/}

							{this.renderGuidanceDialog()}
							<div className={`${classes.keyConcepts} key-concept`}>
								{keyConceptsAndTerminology.map((rootEl, rootIdx) => {
									return (
										<div className={`item item-${rootIdx}`} key={rootIdx}>
											{rootEl.map((el, idx) => {
												if (el.t === TAG.h1) {
													return (
														<Typography key={idx} variant='h4' align='center'>
															{el.v}
														</Typography>
													);
												} else if (el.t === TAG.b) {
													return (
														<Typography key={idx} variant='h5'>
															{el.v}
														</Typography>
													);
												} else if (el.t === TAG.li) {
													return (
														<div
															key={idx}
															variant='body1'
															className='list-item'
														>
															{el.v}
														</div>
													);
												} else {
													return (
														<Typography
															key={idx}
															variant='body1'
															className='item-p'
														>
															{el.v}
														</Typography>
													);
												}
											})}
										</div>
									);
								})}
							</div>
						</div>
					</CSSTransition>
				</>
			);
		};

		const renderReportNavigation = REPORT_NAV.map((e) => {
			return (
				<button
					key={e.id}
					className={`btn-report ${
						this.state.reportActive.id === e.id ? 'active' : ''
					}`}
					onClick={(event) => {
						this.setState({
							reportActive: e,
						});
					}}
				>
					<span className='btn-text'>{e.btnText}</span>
					{!!e.icon && (
						<span className='material-icons-round btn-icon'>{e.icon}</span>
					)}
				</button>
			);
		});

		const reportActive = this.state.reportActive;

		return (
			<div className={classes.container}>
				<div ref={this.headerRef} className={classes.header}>
					<div className='header-content'>
						<div className='logo'>
							<LogotypeDark small={this.state.isTablet} />
							{!hideDashboard && (
								<div className='btn-wrap'>
									<button
										className='btn btn-back'
										onClick={this.handleClickDashboard}
									>
										<span className='material-icons'>arrow_back_ios</span>
										<span className='btn-text'>
											{!this.state.isMobile && dashboardLabel}
										</span>
									</button>
									<div className='share-container'>
										{/* <button
											className='btn btnPrint'
											onClick={this.handleOnClickPdf}
										>
											<SvgIcon
												name='svg-i-printer'
												className={classes.printerIcon}
											/>
										</button> */}
										{!hideShare && (
											<button
												className='btn btn-share'
												onClick={this.handleOnClickShare}
											>
												<span className='material-icons-outlined'>share</span>
												<span className='btn-text'>SHARE</span>
											</button>
										)}
									</div>
								</div>
							)}
						</div>
						{!this.state.isTablet && (
							<div className='btn-reports-wrap'>{renderReportNavigation}</div>
						)}
						{this.state.isTablet && (
							<div className='menu-mobile-wrap'>
								<MenuMobile
									currentReport={this.state.reportActive.id}
									className='report-mobile-menu'
									icon='auto_awesome'
									children={renderReportNavigation}
								/>
							</div>
						)}
					</div>
				</div>

				<div className={classes.infoContainer}>
					{/* <div className='info'>
						<div className='home-destination'>
							<div className='home-destination-content styles-mob-767'>
								<div className='traveller'>
									<div className='traveller-header'>
										<div className='icon'>
											<SvgIcon
												name='svg-i-profile'
												className={classes.travellerIcon}
											/>
										</div>
										<div className='text'>Traveler</div>
									</div>
									<div className='traveller-body'>
										<table cellSpacing='0' cellPadding='0' border='0'>
											<tbody>
												<Tr title='Name' data={fromToDetails.personName} />
												<Tr
													title='Age Group'
													data={fromToDetails.personAgeBucket}
												/>
												<Tr
													title='Male/Female'
													data={fromToDetails.personGender}
												/>
											</tbody>
										</table>
									</div>
								</div>

								<CSSTransition
									key={reportTitle}
									appear
									in={!!reportTitle}
									timeout='100'
									classNames={{
										appear: 'head-appear',
										appearActive: 'head-active-appear',
										appearDone: 'head-done-appear',
										enter: 'head-enter',
										enterActive: 'head-active-enter',
										enterDone: 'head-done-enter',
										exit: 'head-exit',
										exitActive: 'head-active-exit',
										exitDone: 'head-done-exit',
									}}
								>
									<div className='title-section'>
										<div className='head'>{this.state.reportActive.title}</div>
										<div className='sub-head'>
											Produced exclusively for{' '}
											<b>{fromToDetails.personCompany}</b> on{' '}
											{fromToDetails.date}
										</div>
									</div>
								</CSSTransition>

								<div className='location'>
									<div className='content'>
										<div className='home'>
											<span className='icon'>
												{homeIconSvg({
													fill: color.violet,
												})}{' '}
											</span>
											<span className='location-name'>
												{fromToDetails.home}
											</span>
										</div>
										<div className='spacer icon'></div>
										<div className='destination'>
											<span className='material-icons icon'>location_on</span>
											<span className='location-name'>
												{fromToDetails.destination}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={classes.clear} />
					</div> */}
					<TravelerInfo
						reportId={reportActive.id}
						reportTitle={reportActive.title}
						home={fromToDetails.home}
						destination={fromToDetails.destination}
						date={fromToDetails.date}
						personName={fromToDetails.personName}
						personCompany={fromToDetails.personCompany}
						personAgeBucket={fromToDetails.personAgeBucket}
						personGender={fromToDetails.personGender}
						isPreloader={false}
					/>
				</div>
				{renderReport()}
			</div>
		);
	}
}

export default withStyles(styles)(TravelReport);
