import React, { useEffect, useState } from 'react';

import {
	GraphColor,
	SimilarCities,
	SimilarCitiesConflict,
	ViolentCrimeRiskConflict,
	RiskRatingChart11_V2 as RiskRatingChart,
	ViolentCrimeRiskNoData,
	SimilarCitiesNoData,
} from './';

import './ViolentCrimeRisk.scss';
import cn from '../../lib/classNames';

const ViolentCrimeRisk = (props) => {
	const {
		locationHome,
		locationDestination,
		riskValueHome,
		riskValueDestination,
		homeCrimeRiskIndexLocations,
		destinationCrimeRiskIndexLocations,
		isConflictHome,
		isConflictDestination,
		isNoDataHome,
		isNoDataDestination,
	} = props;

	const [show, setShow] = useState(false);

	useEffect(() => {
		setTimeout(() => setShow(true), 100);
	}, []);

	const classRiskColorHome = () => {
		let result;

		if (isConflictHome) {
			result = 'graph-color-conflict';
		} else if (isNoDataHome) {
			result = 'heatmap-00';
		} else {
			result =
				riskValueHome < 10
					? `heatmap-0${riskValueHome}`
					: `heatmap-${riskValueHome}`;
		}

		return result;
	};

	const classRiskColorDestination = () => {
		let result;

		if (isConflictDestination) {
			result = 'graph-color-conflict';
		} else if (isNoDataDestination) {
			result = 'heatmap-00';
		} else {
			result =
				riskValueDestination < 10
					? `heatmap-0${riskValueDestination}`
					: `heatmap-${riskValueDestination}`;
		}

		return result;
	};

	const renderLocation = (nameClass) => {
		let locationDevice = 'block-text-location-' + nameClass;

		return (
			<div
				className={`block-text-location ${nameClass ? locationDevice : ''}`}
				title={locationDestination}
			>
				{locationDestination}
			</div>
		);
	};

	const renderSimilarCities = (
		location,
		locationTitle,
		riskValue,
		isConflict,
		isNoData
	) => {
		if (isConflict) {
			return (
				<SimilarCitiesConflict
					location={location}
					locationTitle={locationTitle}
				/>
			);
		} else if (isNoData) {
			return (
				<SimilarCitiesNoData
					location={location}
					locationTitle={locationTitle}
				/>
			);
		} else {
			return (
				<SimilarCities
					location={location}
					locationTitle={locationTitle}
					riskValue={riskValue}
				/>
			);
		}
	};

	return (
		<div className={cn('violent-crimer-risk-container', { visible: show })}>
			<div className='graph-wrap'>
				<div className='left-box'>
					<div className='similar-cities-container'>
						<div className='similar-cities-content similar-cities-home'>
							<div className='similar-cities-left'>
								{renderSimilarCities(
									locationHome,
									'Home',
									riskValueHome,
									isConflictHome,
									isNoDataHome
								)}
								{/* <SimilarCities
									location={locationHome}
									locationTitle='Home'
									riskValue={riskValueHome}
								/> */}
							</div>
							{riskValueHome > 0 ? (
								<div className='similar-cities-right similar-cities-info'>
									<div className='similar-cities-info-header'>
										<GraphColor className={classRiskColorHome()} />
										<div className='similar-cities-info-text'>
											<h3>
												Similar Class{' '}
												{!isConflictHome && !isNoDataHome && riskValueHome}{' '}
												Cities
											</h3>
											<p title={homeCrimeRiskIndexLocations[0]}>
												{homeCrimeRiskIndexLocations[0]}
											</p>
											<p title={homeCrimeRiskIndexLocations[1]}>
												{homeCrimeRiskIndexLocations[1]}
											</p>
											<p title={homeCrimeRiskIndexLocations[2]}>
												{homeCrimeRiskIndexLocations[2]}
											</p>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
						<div className='similar-cities-content similar-cities-destination'>
							<div className='similar-cities-left'>
								{renderSimilarCities(
									locationDestination,
									'Destination',
									riskValueDestination,
									isConflictDestination,
									isNoDataDestination
								)}
								{/* <SimilarCities
									location={locationDestination}
									locationTitle='Destination'
									riskValue={riskValueDestination}
								/> */}
							</div>
							{riskValueDestination > 0 ? (
								<div className='similar-cities-right similar-cities-info'>
									<div className='similar-cities-info-header'>
										<GraphColor className={classRiskColorDestination()} />
										<div className='similar-cities-info-text'>
											<h3>
												Similar Class{' '}
												{!isConflictDestination &&
													!isNoDataDestination &&
													riskValueDestination}{' '}
												Cities
											</h3>
											<p title={destinationCrimeRiskIndexLocations[0]}>
												{destinationCrimeRiskIndexLocations[0]}
											</p>
											<p title={destinationCrimeRiskIndexLocations[1]}>
												{destinationCrimeRiskIndexLocations[1]}
											</p>
											<p title={destinationCrimeRiskIndexLocations[2]}>
												{destinationCrimeRiskIndexLocations[2]}
											</p>
										</div>
									</div>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				<div className='right-box'>
					<div className='graph-top'>
						{isConflictDestination ? (
							<ViolentCrimeRiskConflict
								destinationLocation={locationDestination}
								description='This location is rated ‘extremely high risk’ due to ongoing armed violence. Risk data should be considered unreliable.'
							/>
						) : isNoDataDestination ? (
							<ViolentCrimeRiskNoData
								destinationLocation={locationDestination}
								description='This location is rated ‘extremely high risk’ due to ongoing armed violence. Risk data should be considered unreliable.'
							/>
						) : (
							<>
								<RiskRatingChart
									value={riskValueDestination}
									valueFontSize={57}
									valueVerticalPosition='157'
									text=''
								/>
								<div className='block-text-index'>ViCRI</div>
								{renderLocation('desktop')}
								<div className='block-text-state'>
									<span>Low Risk</span>
									<span>High Risk</span>
								</div>
								{renderLocation('mobile')}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViolentCrimeRisk;
