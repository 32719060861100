import React from 'react';
import { ReactComponent as BluredLockIcon } from './images/lock.svg';

import cn from '../../../../lib/classNames';
import { Color } from '../../../../theme';

import DistributionBarChart from './DistributionBarChart/DistributionBarChart';
import DistributionsDemo from './DistributionsDemo.json';

import styles from './StatisticsDistributionViCRI.module.scss';

const NO_DATA = 'Distribution data is not available for this report';

const vicriColors = {
	0: Color.heatmap0,
	1: Color.heatmap1,
	2: Color.heatmap2,
	3: Color.heatmap3,
	4: Color.heatmap4,
	5: Color.heatmap5,
	6: Color.heatmap6,
	7: Color.heatmap7,
	8: Color.heatmap8,
	9: Color.heatmap9,
	10: Color.heatmap10,
	11: Color.heatmap11,
	'-1': Color.heatmap12,
};

const getCaption = (isHome, isDestination) => {
	let caption;
	if (isHome && isDestination) {
		caption = 'home-destination';
	} else if (isHome) {
		caption = 'home';
	} else if (isDestination) {
		caption = 'destination';
	}
	return caption;
};

const getColoredDataByLocation = (
	barChartData,
	defaultColor = '#DEE2E5',
	homeColor = '#7D8C97',
	destinationColor = '#DA222F'
) => {
	const { distribution, homeValue, destinationValue } = barChartData;

	// TODO: need for test
	// const { distribution, destinationValue } = barChartData;
	// const homeValue = 5000;

	const lastBucket = distribution[distribution.length - 1];
	const adjustedHomeValue =
		homeValue > lastBucket.max ? lastBucket.max : homeValue;
	const adjustedDestinationValue =
		destinationValue > lastBucket.max ? lastBucket.max : destinationValue;

	return distribution?.map((item) => {
		const isHome =
			adjustedHomeValue > item.min && adjustedHomeValue <= item.max;
		const isDestination =
			adjustedDestinationValue > item.min &&
			adjustedDestinationValue <= item.max;

		const homeFill = isHome ? homeColor : defaultColor;
		const destinationFill = isDestination ? destinationColor : defaultColor;
		const caption = getCaption(isHome, isDestination);

		return {
			...item,
			fill: destinationFill !== defaultColor ? destinationFill : homeFill,
			caption,
		};
	});
};

const getColoredDataByRate = (barChartData, defaultColor = '#DEE2E5') => {
	const CONFLICT = 'Conflict';
	const POOR_DATA = 'Poor data';

	const { distribution, homeValue, destinationValue } = barChartData;

	return distribution.map((item) => {
		const isHome = homeValue === item.max;
		const isDestination = destinationValue === item.max;
		const caption = getCaption(isHome, isDestination);

		return {
			...item,
			fill: vicriColors[item.max] || defaultColor,
			name:
				(item.name === '0' && POOR_DATA) ||
				(item.name === '-1' && CONFLICT) ||
				item.name,
			caption,
			value: item.value === 0 ? 0.1 : item.value,
		};
	});
};

function getVicriTotalStatistics(barChartData) {
	let indexed = 0;
	let conflict = 0;
	let poorData = 0;

	barChartData.distribution.forEach((item) => {
		if (item.max === -1) {
			conflict = item.value;
		} else if (item.max === 0) {
			poorData = item.value;
		} else {
			indexed += item.value;
		}
	});

	return { indexed, conflict, poorData };
}

export default function StatisticsDistributionViCRI(props) {
	const {
		distributions,
		distributionLevel,
		homeCode,
		destinationCode,
		showLabels = false,
		isDemo = false,
	} = props;

	if (!distributions.HR || !distributions.VCR || !distributions.Index)
		return <div className={styles['no-data']}>{NO_DATA}</div>;

	const resultDistributions = isDemo ? DistributionsDemo : distributions;
	// TODO: need for test
	// const resultDistributions = DistributionsDemo;

	const { HR, VCR, Index } = resultDistributions;

	const coloredCrimeHRBarChart = getColoredDataByLocation(HR);
	const coloredCrimeVCRBarChart = getColoredDataByLocation(VCR);
	const coloredCrimeIndexBarChart = getColoredDataByRate(Index);

	const totalVicriStatistics = getVicriTotalStatistics(Index);

	const destinationMinHR = +HR.destinationMinValue.toFixed(1);
	const destinationMaxHR = +HR.destinationMaxValue.toFixed(1);
	const percentDestinationHR = HR.lessValueItemsPercentDestination;
	const destinationValueHR = HR.destinationValue;

	const destinationMinVCR = +VCR.destinationMinValue.toFixed(0);
	const destinationMaxVCR = +VCR.destinationMaxValue.toFixed(0);
	const percentDestinationeVCR = VCR.lessValueItemsPercentDestination;
	const destinationValueVCR = VCR.destinationValue;

	return (
		<div className={styles.distribution}>
			<div className={cn({ [styles.blured]: isDemo })}>
				<div className={styles['distribution-rate-block']}>
					<div className={styles['bar-container']}>
						<DistributionBarChart
							level={distributionLevel}
							data={coloredCrimeHRBarChart}
							destinationValue={destinationValueHR}
							showComparison={showLabels}
							showLabels={showLabels}
							percentComparison={percentDestinationHR}
							type='HR'
							destinationMin={destinationMinHR}
							destinationMax={destinationMaxHR}
							title='Estimated Homicide Rate (HR)'
							description='Per 100,000 people'
							captionX='HR per 100,000 people'
							captionY='Frequency'
							minTick={5}
						/>
					</div>
					<div className={styles['bar-container']}>
						<DistributionBarChart
							level={distributionLevel}
							data={coloredCrimeVCRBarChart}
							destinationValue={destinationValueVCR}
							showComparison={showLabels}
							showLabels={showLabels}
							percentComparison={percentDestinationeVCR}
							type='VCR'
							destinationMin={destinationMinVCR}
							destinationMax={destinationMaxVCR}
							title='Estimated Other Violent Crime Rate (VCR)'
							description='Per 100,000 people'
							captionX='VCR per 100,000 people'
							captionY='Frequency'
							minTick={5}
						/>
					</div>
				</div>
				<div className={styles['bar-container']}>
					<DistributionBarChart
						level={distributionLevel}
						data={coloredCrimeIndexBarChart}
						totalStatistics={totalVicriStatistics}
						title={
							distributionLevel === 'city'
								? 'Top 100 Most Visited Global Cities (Reference Group)'
								: 'ViCRI Index National Rankings Distribution'
						}
						description={
							distributionLevel === 'city'
								? 'Number of Reference Cities by ViCRI Class'
								: 'Number of Countries by ViCRI Class'
						}
						home={homeCode}
						destination={destinationCode}
						showLabels={showLabels}
					/>
				</div>
			</div>
			{isDemo && (
				<div className={styles['demo-message']}>
					<div className={styles['img-wrapper']}>
						<BluredLockIcon className={styles['lock-icon']} />
					</div>
				</div>
			)}
		</div>
	);
}
