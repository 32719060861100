import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import get from 'get-value';

const { Types, Creators } = createActions({
	userLoginRequest: ['email', 'password', 'action'],
	userLoginSuccess: ['data', 'headers', 'status'],
	userLoginFailure: ['headers', 'status'],

	userReceivedErrorMessage: ['data'],
	userResetErrorMessage: null,

	userForgotPasswordRequest: ['email'],
	userForgotPasswordSuccess: ['data', 'headers', 'status'],
	userForgotPasswordFailure: ['data', 'headers', 'status'],

	userLogout: null,

	userDetailsRequest: null,
	userDetailsSuccess: ['data'],
	userDetailsFailure: ['data'],

	userSetSubscribeDetails: ['data'],
	userEnableRequestOnDragMap: ['enabled'],
});

export const UserTypes = Types;
export default Creators;

export const USER_PENDING = {
	userLogin: 'userLogin',
	userDetails: 'userDetails',
	userForgotPassword: 'userForgotPassword',
	userMapboxHodeData: 'userMapboxHodeData',
};

export const ERROR_TYPE = {
	NoSubscriptionException:
		'com.safeesteem.backend.app.exceptions.access.NoSubscriptionException',
	NoBankCardException:
		'com.safeesteem.backend.app.exceptions.access.NoBankCardException',
};

export const INITIAL_BILLING = Immutable({
	name: '',
	country: 'US',
	line1: '',
	line2: '',
	city: '',
	state: '',
	postalCode: '',
	phone: '',
});

export const USER_GROUP = {
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	MODERATOR: 'MODERATOR',
};

export const INITIAL_USER = Immutable({
	id: null,
	email: '',
	name: '',
	firstName: '',
	lastName: '',
	group: null,
	enabled: null,
	createdAt: null,
	updatedAt: null,
	age: null,
	ageGroup: null,
	gender: null, // M, F
	race: null, // id
	city: null,
	locationName: null,
	longitude: null, // city coordinate
	latitude: null, // city coordinate
	billable: false,
	agreementSigned: false,
	enabledRequestOnDragMap: false,
	billing: INITIAL_BILLING,
	parentId: '',
});

export const INITIAL_MAPBOX_DATA = Immutable({
	type: 'FeatureCollection',
	features: [
		{
			id: null,
			area: null,
			level: null,
			gid: null,
			name0: null,
			geometry: {
				type: 'MultiPolygon',
				coordinates: [],
			},
		},
	],
});

export const INITIAL_STATE = Immutable({
	authed: false,
	loaded: false,
	status: null, // 200 - OK, 401 - unauthorized, 403, 406 - agreement
	error: false,
	errorType: null,
	errorMessage: null,
	tokenRefresh: null,
	properties: INITIAL_USER,
	forgotPassword: {
		error: false,
		errorMessage: null,
		success: false,
	},
	subscribe: {
		// preselection
		planId: null,
		promoCode: null,
	},
});

export const adaptCardFromApi = (data) => {
	return {
		paymentMethodId: get(data, 'paymentMethodId'),
		brand: get(data, 'brand'),
		country: get(data, 'country'),
		expMonth: get(data, 'expMonth'),
		expYear: get(data, 'expYear'),
		funding: get(data, 'funding'),
		last4: get(data, 'last4'),
	};
};

export const adaptSubscriptionFromApi = (data) => {
	return {
		id: get(data, 'id'),
		productName: get(data, 'productName'),
		productDescription: get(data, 'productDescription'),
		productAdditionalDescription: get(data, 'productAdditionalDescription'),
		planName: get(data, 'planName'),
		planDescription: get(data, 'planDescription'),
		billingFrequency: get(data, 'billingFrequency'),
		freeReportsPerPeriod: get(data, 'freeReportsPerPeriod'),
		createdDate: get(data, 'createdDate'),
		endDate: get(data, 'endDate'),
		previousPeriodUntil: get(data, 'previousPeriodUntil'),
		currentPeriodUntil: get(data, 'currentPeriodUntil'),
		currentPeriodNumber: get(data, 'currentPeriodNumber'),
		previousPeriodStripeInvoice: get(data, 'previousPeriodStripeInvoice'),
		generatedCurrentReportsNumber: get(data, 'generatedCurrentReportsNumber'),
		disabled: get(data, 'disabled'),
		discountInfo: get(data, 'discountInfo'),
		stripeCustomCouponId: get(data, 'stripeCustomCouponId'),
		stripeCustomCouponUntilDate: get(data, 'stripeCustomCouponUntilDate'),
		currency: get(data, 'currency'),
		collectionMethod: get(data, 'collectionMethod'),
		nextReportBillable: get(data, 'nextReportBillable'),
		running: get(data, 'running'),
		currentBillableReportsNumber: get(data, 'currentBillableReportsNumber'),
		currentPeriodDescription: get(data, 'currentPeriodDescription'),
		initializationCompleted: get(data, 'initializationCompleted'),
		collectionMethodSource: get(data, 'collectionMethodSource'),
	};
};

// login
export const userLoginRequest = (state, action) => {
	const newState = state.merge({
		authed: false,
		loaded: false,
		error: false,
		status: null,
	});
	return newState;
};
export const userLoginSuccess = (state, action) => {
	const { data, status } = action;
	return state.merge(
		{
			authed: true,
			tokenRefresh: data.token,
			status,
		},
		{ deep: true }
	);
};
export const userLoginFailure = (state, action) => {
	const { status } = action;
	return state.merge({
		authed: false,
		error: true,
		status,
	});
};

export const userReceivedErrorMessage = (state, action) => {
	const {
		data: { status, type, message },
	} = action;
	return state.merge({
		status,
		error: true,
		errorType: type,
		errorMessage: message,
	});
};

export const userResetErrorMessage = (state, action) => {
	return state.merge({
		status: null,
		error: false,
		errorType: null,
		errorMessage: null,
	});
};

// forgot password
export const userForgotPasswordRequest = (state, action) => {
	const newState = state.merge(
		{
			forgotPassword: {
				error: false,
				errorMessage: null,
				success: false,
			},
		},
		{ deep: true }
	);
	return newState;
};
export const userForgotPasswordSuccess = (state, action) => {
	const newState = state.merge(
		{
			forgotPassword: {
				error: false,
				errorMessage: null,
				success: true,
			},
		},
		{ deep: true }
	);
	return newState;
};
export const userForgotPasswordFailure = (state, action) => {
	const { status, data } = action;
	return state.merge(
		{
			forgotPassword: {
				error: true,
				errorMessage: data.message || null,
				success: false,
				status,
			},
		},
		{ deep: true }
	);
};

export const userLogout = (state, action) => {
	return INITIAL_STATE;
};

export const adaptBillingForApi = (data) => {
	const result = {};
	if (data.firstName) result.firstName = data.firstName;
	if (data.lastName) result.lastName = data.lastName;
	if (data.phone) result.phone = data.phone;
	if (data.businessName) result.businessName = data.businessName;
	result.address = {};
	if (data.country) result.address.country = data.country;
	if (data.line1) result.address.line1 = data.line1;
	if (data.line2) result.address.line2 = data.line2;
	if (data.city) result.address.city = data.city;
	if (data.state) result.address.state = data.state;
	if (data.postalCode) result.address.postalCode = data.postalCode;
	return result;
};

export const adaptBilling = (data) => {
	return {
		firstName: get(data, 'firstName'),
		lastName: get(data, 'lastName'),
		businessName: get(data, 'businessName'),
		phone: get(data, 'phone', { default: '' }),
		fullName: get(data, 'fullName'),
		actualName: get(data, 'actualName'),
		line1: get(data, 'address.line1', { default: '' }),
		city: get(data, 'address.city', { default: '' }),
		country: get(data, 'address.country', { default: 'US' }),
		line2: get(data, 'address.line2', { default: '' }),
		postalCode: get(data, 'address.postalCode', { default: '' }),
		state: get(data, 'address.state', { default: '' }),
	};
};

// get details
export const adaptDetailsResponse = (data) => {
	if (data) {
		const d = data;
		return {
			id: get(d, 'id'),
			email: get(d, 'email'),
			name: get(d, 'name'),
			group: get(d, 'group'),
			enabled: get(d, 'enabled', { default: false }),
			createdAt: get(d, 'createdAt'),
			updatedAt: get(d, 'updatedAt'),
			age: get(d, 'age'),
			ageGroup: get(d, 'bucket.range'),
			gender: get(d, 'gender'), // M, F
			race: get(d, 'race.id', { default: null }),
			city: null,
			locationName: get(d, 'locationName'),
			longitude: get(d, 'locationX'),
			latitude: get(d, 'locationY'),
			companyName: get(d, 'companyName'),
			billable: get(d, 'billable', { default: false }),
			agreementSigned: get(d, 'agreementSigned', { default: false }),
			billing: adaptBilling(get(d, 'billingInfo', { default: {} })),
			parentId: get(d, 'parentId', { default: null }),
		};
	}
	return INITIAL_USER;
};
export const userDetailsRequest = (state, action) => {
	const newState = state.merge({
		loaded: false,
		error: false,
		status: null,
	});
	return newState;
};
export const userDetailsSuccess = (state, action) => {
	const { data, status } = action;
	return state.merge(
		{
			loaded: true,
			status,
			properties: adaptDetailsResponse(data),
		},
		{ deep: true }
	);
};
export const userDetailsFailure = (state, action) => {
	const { status } = action;
	return state.merge({
		loaded: false,
		error: true,
		status,
	});
};

const userSetSubscribeDetails = (state, action) => {
	const planId = get(action, 'data.planId');
	const promoCode = get(action, 'data.promoCode');
	return state.merge(
		{
			subscribe: { planId, promoCode },
		},
		{ deep: true }
	);
};

const userEnableRequestOnDragMap = (state, action) => {
	const enabledRequestOnDragMap = get(action, 'enabled');
	return state.merge(
		{
			properties: { enabledRequestOnDragMap },
		},
		{ deep: true }
	);
};

export const reducer = createReducer(INITIAL_STATE, {
	[Types.USER_LOGIN_REQUEST]: userLoginRequest,
	[Types.USER_LOGIN_SUCCESS]: userLoginSuccess,
	[Types.USER_LOGIN_FAILURE]: userLoginFailure,

	[Types.USER_RECEIVED_ERROR_MESSAGE]: userReceivedErrorMessage,
	[Types.USER_RESET_ERROR_MESSAGE]: userResetErrorMessage,

	[Types.USER_FORGOT_PASSWORD_REQUEST]: userForgotPasswordRequest,
	[Types.USER_FORGOT_PASSWORD_SUCCESS]: userForgotPasswordSuccess,
	[Types.USER_FORGOT_PASSWORD_FAILURE]: userForgotPasswordFailure,

	[Types.USER_LOGOUT]: userLogout,

	[Types.USER_DETAILS_REQUEST]: userDetailsRequest,
	[Types.USER_DETAILS_SUCCESS]: userDetailsSuccess,
	[Types.USER_DETAILS_FAILURE]: userDetailsFailure,

	[Types.USER_SET_SUBSCRIBE_DETAILS]: userSetSubscribeDetails,
	[Types.USER_ENABLE_REQUEST_ON_DRAG_MAP]: userEnableRequestOnDragMap,
});
