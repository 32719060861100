import React from 'react';
import ReactTooltip from 'react-tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import cn from '../../../lib/classNames';

import styles from './TooltipVicri.module.scss';

export default function TooltipVicri() {
	const tooltipContent = (
		<>
			This rating and the ViCRI index represent the most accurate estimation
			efforts available today about the specific risk of serious violent crime
			at the national and urban level on a global scale (measures homicide,
			aggravated assault, robbery, and rape risks). Visit{' '}
			<a
				className='tooltip-link-text'
				href='https://violenceriskindex.org/'
				target='_blank'
				rel='noopener noreferrer'
			>
				violenceriskindex.org
			</a>{' '}
			for additional information.
		</>
	);

	const tooltipId = 'vicri-tooltip';

	return (
		<>
			<span
				className={styles.icon}
				data-for={tooltipId}
				data-tip='custom show'
				data-event='click focus'
			>
				<InfoOutlinedIcon
					className={cn(styles.icon, 'icons-animation')}
					fontSize='large'
				/>
			</span>
			<ReactTooltip
				className={styles.body}
				id={tooltipId}
				globalEventOff='click'
				// effect='solid'
				getContent={() => tooltipContent}
				delayHide={100}
				delayShow={100}
				delayUpdate={200}
			/>
		</>
	);
}
