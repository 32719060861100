import React from 'react';

import styles from './StatisticsTotalNumber.module.scss';

export function StatisticsTotalNumber(props) {
	const { indexed, conflict, poorData } = props;

	return (
		<div className={styles.statistics}>
			<div className={styles.item}>
				<div className={styles.text}>Indexed</div>
				<div className={styles.number}>{indexed}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.text}>Conflict</div>
				<div className={styles.number}>{conflict}</div>
			</div>
			<div className={styles.item}>
				<div className={styles.text}>Poor data</div>
				<div className={styles.number}>{poorData}</div>
			</div>
		</div>
	);
}
