import React from 'react';
import { useEffect } from 'react';

import cn from '../../../../lib/classNames';

import { World } from '../../../Covid19';
import WorldColoredMap from './WorldColoredMap/WorldColoredMap';

import styles from './StatisticsMapViCRI.module.scss';

export default function StatisticsMapViCRI(props) {
	const {
		countries,
		crimeIndexGeography,
		home,
		destination,
		destinationCoordinates,
	} = props;

	const addClassToParent = (child, parent) => {
		if (child && parent) {
			const getChild = document.querySelectorAll(child);

			getChild.forEach((e) => {
				const getParent = e.closest(parent);
				getParent && getParent.classList.add(styles['scale-item-with-data']);
			});
		}
	};

	useEffect(() => {
		addClassToParent('.scale-item .location-title', '.scale-item');
	}, [home]);

	const renderCountryStat = (idx, location) => {
		let countryCrimeBucket = location?.countryCrimeBucket;

		if (location?.countryCircumstance?.includes('NO_DATA')) {
			countryCrimeBucket = 0;
		} else if (location?.countryCircumstance?.includes('MILITARY_CONFLICT')) {
			countryCrimeBucket = 12;
		}

		if (idx === countryCrimeBucket && location === home) {
			return (
				<span className={cn(styles['location-title'], 'location-title')}>
					{location?.countryCode}
				</span>
			);
		}

		if (
			idx === countryCrimeBucket &&
			home?.countryCode !== destination?.countryCode
		) {
			return (
				<span className={cn(styles['location-title'], 'location-title')}>
					{destination?.countryCode}
				</span>
			);
		}

		return null;
	};

	const renderScaleItems = () => {
		const result = [];

		const totalScaleNum = 12;

		for (let i = 0; i <= totalScaleNum; i++) {
			const idx = i < 10 ? `0${i}` : i;

			let firstData;

			if (i === 0) {
				firstData = 'Poor\u00A0data';
			} else if (i === totalScaleNum) {
				firstData = 'Conflict';
			} else {
				firstData = i;
			}

			result.push(
				<div
					className={cn(
						styles['scale-item'],
						styles[`scale-item-${idx}`],
						'scale-item'
					)}
					key={idx}
				>
					<div className={styles['box-stat']}>
						<span className={cn(styles.heatmap, `heatmap-${idx}`)} />
						<div className={styles['block-stat-num']}>
							<span className={styles['stat-num-devider']}> - </span>
							<span className={cn(styles['stat-num'], styles['stat-num-one'])}>
								{firstData}
							</span>
						</div>
					</div>
					{renderCountryStat(i, home)}
					{renderCountryStat(i, destination)}
				</div>
			);
		}

		return result;
	};

	return (
		<div className={styles['box-map']}>
			{crimeIndexGeography ? (
				<WorldColoredMap
					destinationCoordinates={destinationCoordinates}
					crimeIndexGeography={crimeIndexGeography}
					showPopup
					showMarker
					projection='globe'
					disableMoving={false}
				/>
			) : (
				<World items={countries} colorsTheme='' variant='map-vicri' />
			)}

			<div className={styles['block-map-scale']}>{renderScaleItems()}</div>
		</div>
	);
}
